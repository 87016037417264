 @import '../../../../styles/index';

.card {
 display: flex;
 flex-direction: column;
 gap: 25px;
 align-items: center;
 position: relative;
 width: 270px;
 height: 400px;
 padding: 25px 30px;
 background: $c-white;
 box-shadow: 0 0 9.8px rgba(0, 0, 0, 0.25);
 border-radius: 8px;
 transition: 0.2s;
 overflow: hidden;

 &::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #B5EDFF;
  transition: 0.2s;
 }

 &:hover {
  box-shadow: 0 0 9.8px rgba(0, 0, 0, 0.5);

  &::after {
   height: 8px;
  }
 }

 .head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &_title {
   position: absolute;
   text-align: center;
   @include text (700, 28px, 34px, 0, $main-text-c);
  }

  .circle {
   transition: 0.3s;

   & circle {
    fill: #F5F7FF;
   }
  }
 }

 &_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 100%;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(21%, 26%, 37%, 0.5);

  .card_type {
   display: flex;
   justify-content: center;
   gap: 5px;
   font-weight: 500;
   font-size: 21px;

   .icon {
    width: 21px;
    height: 21px;
   }
  }

  .article {
   text-align: center;
   font-weight: 400;
   font-size: 16px;
  }
 }

 .amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;

  &_value {
   font-weight: 700;
   font-size: 20px;
   line-height: 26px;
  }

  .counter {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   border: 1.3px solid #35435E;
   background-color: $main-button-c;
   border-radius: 50px;
   overflow: hidden;

   .border {
    height: 70%;
    border-left: 1px solid rgba(21%, 26%, 37%, 0.5);
   }

   .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 36px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
     box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.15);
    }
   }
  }
 }
}