@import '../../../../styles/index';

.details {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &_wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px 25px;
    border-bottom: 0.5px solid rgba(21%, 26%, 37%, 0.5);
  }

  &_header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 600px;
    width: 100%;
  }

  &_qualification {
    max-width: 600px;
    width: 100%;
    align-content: flex-start;
  }

  .description {
    max-width: 650px;
    width: 100%;
    align-content: flex-start;
    align-self: center;
    padding: 20px 25px;
    word-break: break-word;
  }
}