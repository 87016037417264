@import "../../../styles/index";

.textarea {
  font-size: 0.9375rem;
  resize: none;
  padding: 0.5rem 0.75rem;
  margin: 2px;
  // max-height: 600px;
  &:focus {
    outline-color: $main-employer-c;
  }
}
.textarea_error {
  border-color: $c-red;
}

.textarea_candidate {
  &:focus {
    outline-color: $main-candidate-c;
  }
}

.textarea_admin {
  &:focus {
    outline-color: $main-admin-c;
  }
}

.title {
  font-weight: 500;
}

.title_error {
  color: $c-red;
}
