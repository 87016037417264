@import '../../styles/utils/variables';

.navigationMenu {
  // z-index: 1;
  // position: sticky;
  // top: 64px;
  display: flex;
  gap: 0.5rem;
  margin: 0 auto;
  padding: 1.25rem 0.5rem;
  & > a:global(.active) {
    background-color: $main-button-c;
    & > span {
      font-weight: 500;
    }
  }
  & > a:not(:global(.active)) {
    background-color: #f4ebfb;
  }
}
