@import "../../../styles/index";

.header {
  @media screen and (min-width: $laptops-breakpoint) {
    justify-content: flex-start;
  }

  .logo-main {
    width: 100px;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    padding-left: 35px;
    flex-grow: 1;

    @media screen and (min-width: $laptops-breakpoint) {
      width: 100%;
      flex-grow: 0;
      justify-content: space-between;
    }

    @media screen and (max-width: $tablets-portait-breakpoint) {
      flex-grow: 0;
    }

    @media screen and (max-width: $tablets-landscape-breakpoint) {
      //flex-grow: 0;
      padding-left: 20px;
    }

    &--jobs-category {
      display: flex;

      @media screen and (max-width: $tablets-portait-breakpoint) {
        display: none;
      }

      li:first-child {
        margin-right: 10px;
      }
    }

    &--main {
      display: flex;
      justify-self: flex-end;

      li:first-child {
        margin-right: 10px;
      }
    }

    &--main-auth {
      position: relative;
      display: flex;
      align-items: center;

      li:nth-child(n + 1) {
        margin-right: 20px;

        @media screen and (max-width: $tablets-landscape-breakpoint) {
          margin-right: 15px;
        }
      }

      li:last-child {
        margin-right: 0;
        margin-left: -5px;
      }
    }
  }
}
