@import "../../../styles/index";

.custom_link {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: $main-button-c;
  max-width: 130px;
  width: 100%;
  height: 32px;
  border-radius: 50px;
  @include text(500, 16px);
}
