@import "../../styles/index";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-top: 80px;
  margin-bottom: 30px;
}

.email {
  text-align: center;
  .link {

  }
}

.view_all {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 145px;
  height: 35px;
  border: none;
  border-radius: 57px;
  text-align: center;
  color: $main-blue;
  background-size: 13px;
  background-repeat: no-repeat;
  cursor: pointer;
  transition-duration: $anim-time;
  font-weight: 700;
  font-size: 13px;
  user-select: none;
  background-color: $main-candidate-c;
  background-position-x: 93%;
  background-position-y: center;

  &:hover {
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.15);
  }
}