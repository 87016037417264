@import "../../styles/index";

.form {
  width: 100%;

  &__heading {
    margin-bottom: 15px;
    font-weight: 700;
    display: inline-block;
    @include text (500, 16px, 20px, $_color: $main-text-c);
  }

  &__fields--list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    //width: fit-content;
  }
}
