@import "../../styles/index";

.search-work {
  display: flex;
  flex-direction: column;
  background-color: $main-candidate-c;
  align-items: center;
;

  &__filters {
    padding: 30px 0;

    button:nth-child(n + 1) {
      margin-right: 5px;
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: $c-white;
  }
}
