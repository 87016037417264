@import "../../../styles/index";

.ui-checkbox {
  outline: none;

  &__label {
    outline: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    max-width: 225px;
    min-height: 32px;
    background-color: $bg-unselected-point;
    border-radius: 50px;
    padding-left: 28px;
    padding-right: 24px;
    text-align: center;
    @include text (500, 16px, 20px, $_color: $main-text-c);
    cursor: pointer;
    font-weight: 400;
    @media (max-width: $mobile-landscape-breakpoint) {
      font-size: 13px;
    }
    @media (min-width: $tablets-portait-breakpoint) {
      min-width: 162px;
      width: 100%;
    }
  }

  //&__label--active {
  //  background-color: $bg-selected-point;
  //  color: $c-white;
  //  background-image: url(../../../assets/icons/active-check.svg);
  //  background-repeat: no-repeat;
  //  background-position: 10px;
  //  background-size: 14px;
  //}

  &__input {
   display: none;
  }


  &__input:checked + .ui-checkbox__label--active {
    background-color: $bg-selected-point;
    color: $c-white;
    background-image: url(../../../assets/icons/active-check.svg);
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 14px;
  }
}
