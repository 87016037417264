@import "../../../styles/index";

.profile {
  padding: 0 20px;

  @media (max-width: $mobile-landscape-breakpoint) {
    padding: 0;
  }

  &__information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 930px;
    min-width: 360px;
    margin: 30px auto;

    &_main-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 32px;
      @media (max-width: $tablets-portait-breakpoint) {
        flex-direction: column;
      }
    }

    &--photo-wrapper {
      display: flex;
      justify-content: center;
      position: relative;
      height: fit-content;
      max-width: 332px;
      margin: 0 auto;

      & > .photo-img {
        width: 332px;
        height: 332px;
        cursor: pointer;
        object-fit: cover;

        @media screen and (max-width: $tablets-portait-breakpoint) {
          width: 280px;
          height: 280px;
        }
      }
      & > input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 2;
        cursor: pointer !important;
        &::-webkit-file-upload-button {
          cursor: pointer;
        }
      }
    }

    &--inputs-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }

    &--resume-wrapper {
      margin-top: 44px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $main-text-c;
      }
    }

    &--resume-textarea {
      padding: 5px;
      border: 1px solid #DADADA;
      border-radius: 4px;
      width: 100%;
      height: 144px;
      outline: none;
      resize: none;
      font-size: 14px;
    }

    &--resume-file-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      align-items: center;
    }

    &--resume-file-btn {
      width: 144px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 500;
      border: 1px solid $main-blue;
      height: 25px;
      overflow: hidden;
      margin-right: 4px;
      cursor: pointer;
      position: relative;
      transition: $anim-time;

      & * {
        cursor: pointer;
      }
      & > input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        right: 0;
        z-index: 2;
        cursor: pointer !important;
        &::-webkit-file-upload-button {
          cursor: pointer;
        }
      }

      &:hover {
        color: $c-white;
        background: $main-blue;
      }
    }

    &--resume-file {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      &-delete {
        cursor: pointer;
        transform: scale(0.5);
        & > path {
          stroke: $main-blue;
          opacity: 1;
        }
      }
    }

    &--resume-name {
      font-size: 14px;
      line-height: 17px;
      height: 17px;
      font-weight: 400;
      border-bottom: 1px solid $main-blue;
      color: $main-blue;
    }
  }

  &__details {
    &--wrapper {
      display: grid;
      margin-bottom: 36px;
      grid-template-columns: repeat(3, auto);
      grid-template-areas:
                "c  gr r"
                "ge b  .";
      @media screen and (max-width: $tablets-portait-breakpoint) {
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas:
                "c c r r r ge"
                "gr gr gr gr b  b";
      }
      @media screen and (max-width: $mobile-landscape-breakpoint) {
        grid-template-columns: repeat(7, 1fr);
        grid-template-areas:
                "c  c  c  c  b  b  b"
                "ge ge ge r  r  r  r"
                "gr gr gr gr gr gr gr";
      }
      gap: 36px 16px;
    }

    &--citizenship {
      grid-area: c;
    }

    &--birthday {
      max-width: 160px;
      grid-area: b;
      @media screen and (max-width: $mobile-landscape-breakpoint) {
        justify-self: end;
      }
    }

    &--gender {
      grid-area: ge;
    }

    &--group {
      grid-area: gr;
      @media screen and (min-width: $tablets-portait-breakpoint) {
        justify-self: center;
      }
      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &--race {
      grid-area: r;
      @media screen and (min-width: $tablets-portait-breakpoint) {
        justify-self: end;
      }
      @media screen and (max-width: $tablets-portait-breakpoint) {
        justify-self: center;
        ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }
      @media screen and (max-width: $mobile-landscape-breakpoint) {
        justify-self: end;
        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }
      }
    }
  }

  &__single--form {
      ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        li {
          justify-self: center;
          width: 200px;
          label {
            height: 100%;
          }
        }
      }
    @media screen and (max-width: $tablets-landscape-breakpoint) {
      ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        li {
          justify-self: center;
          width: 240px;
          label {
            height: 100%;
          }
        }
      }
    }
    @media screen and (max-width: $tablets-portait-breakpoint) {
      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        li {
          justify-self: center;
          width: 240px;
          label {
            height: 100%;
          }
        }
      }
    }
    @media screen and (max-width: $mobile-landscape-breakpoint) {
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        li {
          width: 200px;
        }
      }
    }
  }

  &__notifications {
    @media screen and (min-width: $mobile-landscape-breakpoint + 2px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__notifications--form {
    @media screen and (max-width: $mobile-landscape-breakpoint) {
      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important;
      }
    }
  }

  &__notifications-type--form {
      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important;
        @media screen and (max-width: $mobile-landscape-breakpoint) {
          padding: 0 44px;
        }
      }
  }

  &__buttons {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    gap: 20px;

    &--item {
      width: 180px;
      height: 42px;
      @media screen and (max-width: 465px) {
        width: 140px;
      }
    }

    &--delete {
      background: $c-red !important;
      color: #fff !important;
      font-weight: 450;
    }
  }

  &__error {
    text-align: center;
    color: $c-red;
    margin-top: 16px;
  }

}

.required__error {
  color: $c-red;
}

.profile__selectbox {
  margin-top: 5px;
  width: 202px !important;
  .dropdown__heading {
    margin-left: 0;
    margin-bottom: 3px;
    font-weight: 500;
  }
  .dropdown__list {
    max-height: 300px;
    overflow: auto;
  }
  .dropdown__wrapper {
    width: 80px !important;
  }
}
