@import "../../../../styles/index";

.main {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 20px;
  background-color: $main-employer-c;

  &_content {
    position: relative;
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    gap: 10px;
    padding-top: 30px;

    @media screen and (max-width: 985px) {
      flex-direction: column;
      align-items: center;
    }

    .text_content {
      max-width: 600px;
      margin-top: 35px;

      @media screen and (max-width: 985px) {
        margin-top: 0;
      }

      .post_btn {
        width: 194px;
        height: 45px;
        margin-top: 44px;
        @include text (600, 18px);
        background-color: $main-button-c;

        @media screen and (max-width: 985px) {
          position: absolute;
          bottom: -22px;
          left: calc(50% - 97px);
          @include text (600, 16px);
        }
      }

      .main_text {
        @include text (700, 60px, 74px, 0, #FFC700);

        @media screen and (max-width: 985px) {
          @include text (700, 33px, 41px, 0, #FFC700);
        }

        & > span {
          color: #fff;
        }
      }

      .secondary_text {
        @include text (500, 24px, 29px, 0, $c-white);
        margin-top: 30px;

        @media screen and (max-width: 985px) {
          @include text (400, 16px, 20px, 0, $c-white);
          margin-bottom: 30px;
        }
      }
    }

    .main_img {
      width: 540px;
      height: 550px;

      @media screen and (max-width: $laptops-breakpoint) {
        width: 500px;
        height: 510px;
      }

      @media screen and (max-width: 985px) {
        width: 400px;
        height: 400px;
      }

      @media screen and (max-width: $mobile-landscape-breakpoint) {
        width: 320px;
        height: 320px;
      }
    }
  }
}
