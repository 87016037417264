@import "../../../styles/index";

.tg_link {
  display: flex;
  align-items: center;
  gap: 7px;
  outline: none;
  text-decoration: none;
  @include text(400, 16px, 20px, $_color: $main-text-c);

  &:hover {
    cursor: pointer;
  }
}

