@import "../../../styles/index";

.Button__link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 145px;
  height: 34px;
  border: none;
  border-radius: 57px;
  text-align: center;
  color: $main-blue;
  background-size: 13px;
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: center;
  cursor: pointer;
  transition-duration: $anim-time;
  font-weight: 700;
  font-size: 13px;
  user-select: none;

  &:hover {
    //border-color: $c-red;
    //opacity: 0.9;
    //box-shadow: 0 0 0 1px $main-blue;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    //border-color: $c-light-gray;
    cursor: default;
  }

  &--for-employers {
    font-weight: 700;
    color: #FFC700;
    background-color: $main-blue;
  }

  &--startIcon {
    padding-left: 15px;
    background-image: url("../../../assets/icons/icon-for-employers.svg");
  }

  &--login {
    background-color: $main-candidate-c;
    background-image: url("../../../assets/icons/login-icon.svg");
    background-position-x: 93%;
    background-position-y: center;
    padding-right: 10px;
  }
}
