@import '../../../../styles/index';

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin: 0 auto;
  padding: 20px 0;
  max-width: 750px;
  width: 100%;

  .heading {
    align-self: center;
    font-size: 20px;
    //text-transform: uppercase;
  }

  .circular {
    margin: 10px auto;
  }

  .reviews {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    .reviews_list {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}