@import "../../../styles/index";

.custom_link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  @include text (400, 15px, 30px, $_color: $main-text-c);

  span {
    margin-left: 5px;
    transition: 0.05s;
    @include text (400, 15px, 30px, $_color: $main-text-c);

    &::before {
      right: 0;
      display: block;
      content: attr(about);
      height: 0;
      @include text (700, 15px, $_color: $main-text-c);
      overflow: hidden;
      visibility: hidden;
    }
  }

  &::after {
    position: absolute;
    content: "";
    bottom: 2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: $main-text-c;
    border-radius: 10px;
    transition: width 0.3s;
  }

  &:hover::after {
    width: 100%;
  }

  &:hover {
    transform:  translateY(-1px);

    span {
      font-weight: 600;
    }
  }
}

.active_candidate {
  transform:  translateY(-1px);

  span {
    font-weight: 600;
  }
  &::after {
    background-color: $main-text-c;
    width: 100%;
  }
}

.active_employer {
  transform:  translateY(-1px);

  span {
    font-weight: 600;
  }
  &::after {
    background-color: $main-text-c;
    width: 100%;
  }
}

.active_admin {
  transform:  translateY(-1px);

  span {
    font-weight: 600;
  }
  &::after {
    background-color: $main-text-c;
    width: 100%;
  }
}

.ed-nav__link--active {
  span {
    font-weight: 700;
  }

  &::after {
    width: 100%;
  }
}

.custom_bt_link {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  background-color: $main-no-active-button-c;
  height: 48px;
  padding: 0 35px;
  border-radius: 75px;

  @media (max-width: $mobile-landscape-breakpoint) {
    padding: 0 10px;
    width: 105px;
    height: 38px;
  }

  span {
    transition: 0.05s;
    text-align: center;
    @include text(400, 18px, 25px, $_color: $main-text-c);

    @media (max-width: $mobile-landscape-breakpoint) {
      font-size: 13px;
      line-height: 16px;
    }
  }

  &:global(.active) {
    background-color: $main-button-c;
    & > span {
      font-weight: 500;
    }
  }
  &:not(:global(.active)) {
    background-color: $main-no-active-button-c;
  }
}

.bt_link_active {
  background-color: $main-button-c;

  span {
    transition: 0.05s;
    font-weight: 600;
    @include text(400, 18px, 25px, $_color: $main-text-c);

    @media (max-width: $mobile-landscape-breakpoint) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}