@import "../../../styles/index";
@import "../UIButtonLink/UIButtonLink";

.Button__filter {
  width: 160px;
  height: 45px;
  transition-duration: $anim-time;
  transition-property: background-color;
  font-weight: 500;
  font-size: 18px;

  @media screen and (max-width: $tablets-portait-breakpoint) {
    width: 105px;
    height: 32px;
    font-size: 14px;
  }

  &--inactive {
    background-color: #D8E0FF;
    font-weight: 400;
  }
}
