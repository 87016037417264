@import '../../styles/index';

label.salary {
  display: flex;
  margin: 0.8rem 0 0.5rem 0;

  .title {
    font-weight: 500;
  }

  .title_error {
    color: $c-red;
  }

  .input {
    margin: 0 0.25rem;
    width: 4.5rem;
    padding: 0 5px;
    text-align: center;
    //border-color: #7030a0;
  }

  .to {
    margin: 0 0.25rem;
  }

  .input_candidate {
    &:focus {
      outline-color: $main-candidate-c;
    }
  }
  .input_emplr {
    &:focus {
      outline-color: $main-employer-c;
    }
  }

  .input_admin {
    &:focus {
      outline-color: $main-admin-c;
    }
  }

  .error {
    border-color: $c-red;
    border-radius: 4px;
    border-width: 1px;
  }
}
