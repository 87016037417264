@import "../../../../styles/index";

.payment {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  max-width: 1200px;
  margin: 50px auto;
  //background-color: #3994D6;


  @media screen and (max-width: 750px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  &_info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    font-size: 21px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid $main-employer-c;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
    width: 300px;
    height: 300px;

    .purchase_amount {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      gap: 20px;
      font-size: 20px;
      font-weight: 500;

      .totals {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        margin: 10px 0;
        @include text(500, 16px, 20px);
      }

      .line {
        width: 100%;
        border-bottom: 1px solid rgba(21%, 26%, 37%, 0.5);
      }

      .total_amount {
        @include text(600, 26px);
      }
    }
  }
}