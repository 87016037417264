@import "../../styles/index";

.locations {
  margin-bottom: 80px;


  .heading {
    margin-bottom: 30px;
    //margin-left: 60px;
    @include text (600, 27px, $_color: $main-text-c);

    span {
      color: $main-candidate-c
    }
  }

  &_list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;

    @media screen and (max-width: $laptops-breakpoint) {
      display: grid;
      grid-template-columns: repeat(3, 185px);
      grid-gap: 15px;
    }

    &_item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 185px;
      height: 47px;
      border-radius: 63px;
      padding: 8px;
      background-color: $main-candidate-c;
      cursor: pointer;
      transition: 0.2s;

      .location_heading {
        @include text (500, 16px, $_color: $main-text-c);
      }

      &:hover {
        transform: translateY(-8px);
        background-color: $main-button-c;
        box-shadow: 0 4.5px 5.8px rgba(0, 0, 0, 0.15);
      }

      &::before {
        position: absolute;
        left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        content: attr(about);
        height: 32px;
        width: 32px;
        border-radius: 30px;
        @include text (500, 15px, $_color: $main-candidate-c);
        background-color: #FFFFFF;
        transition: 0.2s;
      }

      &:hover::before {
        color: $main-text-c
      }
    }
  }


}
