.details {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 5px;
  align-items: flex-start;

  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  word-break: break-word;

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 15px;
    margin-right: 12px;
    //background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .company {
    padding-left: 20px;
    position: relative;
    &_icon {
      background-image: url(../../assets/icons/company-icon.svg);
    }
  }

  .location {
    padding-left: 20px;
    position: relative;
    &_icon {
      background-image: url(../../assets/icons/loc-icon.svg);
    }
  }

  .rate {
    padding-left: 20px;
    position: relative;
    &_icon {
      background-image: url(../../assets/icons/price-icon.svg);
    }
  }

  .time {
    padding-left: 20px;
    position: relative;
    &_icon {
      background-image: url(../../assets/icons/clock-icon.svg);
    }
  }
}