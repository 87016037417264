@import '../../../../styles/index';

.jobs_list {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  overflow-x: visible;
  direction: rtl;
  padding: 5px 10px 10px 0;

  @extend %scroll;

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 430px;
    max-height: 210px;
    height: 100%;
    justify-content: space-between;
    padding: 15px 25px;
    border-radius: 6px;
    //box-shadow: 0 0 23px rgba(0, 0, 0, 0.15);
    background-color: #F1F1F1;
    direction: ltr;
    transition: 0.1s;
    cursor: pointer;

    @media screen and (max-width: $laptops-breakpoint) {
      width: 380px;
    }

    &:hover {
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
    }

    &_info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 10px 0;
    }

    .footer {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      
      .status {
        display: block;
        position: absolute;
        right: 0;
        bottom: 25px;
      }
    }
  }

  .card_active {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF !important;
  }
}