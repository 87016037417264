@import "../../../../../styles/index";

.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 980px;
  height: 600px;
  width: 100%;
  margin: 50px auto 0 auto;

  &__title {
    position: relative;
    z-index: 1;
    margin: 0;
    padding-bottom: 25px;
    @include text(700, 45px, 55px, $_color: #ffc700);

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      right: -15px;
      top: -5px;
      width: 60px;
      height: 60px;
      background: rgba(164, 231, 252, 0.4);;
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      border-radius: 50px;
    }

    &::before {
      content: "";
      position: absolute;
      right: -45px;
      top: -20px;
      width: 30px;
      height: 30px;
      background: rgba(164, 231, 252, 0.4);;
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      border-radius: 50px;
    }
  }

  .accordion {
    list-style: none;
    color: $c-white;

    min-height: 350px;

    @media screen and (max-width: $tablets-portait-breakpoint) {
      min-height: 400px;
      padding: 0 20px;
    }

    @media screen and (max-width: $mobile-landscape-breakpoint) {
      min-height: 420px;
    }

    &_item {
      margin-top: 5px;

      &:nth-child(1) {
        border-top: 1px solid #E2E2E2;
      }
    }

    &_title {

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      padding: 25px 15px 0;
      cursor: pointer;

      .question {
        @include text(600, 22px, 27px, $_color: $c-white);
      }

      & > span {
        font-size: 30px;
        font-weight: lighter;
        transition: 0.3s;
      }

      span.active {
        rotate: (-135deg);
      }
    }

    &_content {
      padding: 0 30px;
      margin-top: 25px;
      //padding-bottom: 25px;
      @include text(400, 16px, 20px, $_color: $c-white);
      max-height: 0;
      overflow: hidden;
      transition: all 0.4s cubic-bezier(0, 1, 0, 1);

      border-bottom: 1px solid #E2E2E2;

    }

    &_content.show {
      padding-bottom: 25px;
      height: 50px;
      max-height: 999px;
      transition: all 0.4s  cubic-bezier(1, 0, 1, 0);

      @media screen and (max-width: $mobile-landscape-breakpoint) {
        height: 70px;
      }
    }
  }
}
