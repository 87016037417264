@import '../../../../styles/index';

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
  max-height: 310px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
  position: relative;


  &_info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0;
  }

  &_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 30px;
    margin: 0 auto;
    background-color: $main-button-c;
    border-radius: 20px;
    @include text (500, 16px, 16px, 0, $main-text-c);
  }
}
