@import "../../../styles/index";

.selector {
  width: 130px;

  &__label {
    margin-bottom: 8px;
    text-align: center;
    @include text (500, 15px, $_color: $main-text-c);
  }

  &__list {
    display: flex;
    margin-bottom: 30px;
  }

  &__item {
    position:relative;
    display:flex;
    width: 65px;
    height: 26px;
    align-items:center;

    &--radio {
      appearance:none;
      display:none;
    }

    &--label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position:relative;
      text-align:center;
      transition-duration: 0.3s;
      transition-property:transform, color, box-shadow;
      transform:none;
      background-color: $bg-unselected-point;
      @include text (400, 13px, 16px, $_color: $main-text-c);

      &:hover {
        cursor: pointer;
      }
    }

    &--radio-left + &--label {
      border-top-left-radius: 200px;
      border-bottom-left-radius: 200px;
    }

    &--radio-right + &--label {
      border-top-right-radius: 200px;
      border-bottom-right-radius: 200px;
    }

    &--radio:checked + &--label {
      background-color: $bg-selected-point;
      color: $c-white;
      font-weight: 600;
      box-shadow:0 0 2px rgba(0,0,0,.5),0 1px 2px rgba(0,0,0,.5);
    }
  }
}
