@import "../../../../styles/index";

.price_list_wrap {
  padding: 40px 0;
  min-height: 500px;

  .title {
    text-align: center;
    font-size: 28px;
  }

  .price_list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    justify-content: center;
    gap: 30px;
    margin: 30px 0 40px;
  }
}
