@import '../../../../styles/index';

.purchase_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 35px;

  .purchase_item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 315px;
    height: 260px;
    padding: 25px 22px;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow: hidden;
    transition: 0.2s;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 5px;
        background: #B5EDFF;
        transition: 0.2s;
    }

    &:hover {
      box-shadow: 1px 1px 18px rgba(0, 0, 0, 0.25);

      &::after {
        height: 10px;
      }
    }

    .details_list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-self: flex-start;
      height: 100%;
      padding-bottom: 20px;

      .details_item {
        display: flex;
        @include text(400, 16px, 18px);
      }

      .details_heading {
        font-weight: 500;
        margin-right: 5px;
      }
    }
  }
}
