@import "./variables";
@import "./mixins";

%h1 {
  @include text (700, 32px, 41px, -0.01em);
}

%h2 {
  @include text (700, 22px, 31px);
}

%h3 {
  @include text (600, 20px, 26px);
}

%uppercase {
  @include text (500, 18px, 23px);

  text-transform: uppercase;
}

%buttons {
  @include text (500, 16px, 21px, 0.05em);
}

%body-text {
  @include text (400, 14px, 21px, 0);
}

%small-text {
  @include text (600, 12px, 15px);
}

%scroll {
  &::-webkit-scrollbar {
    display: block;
    width: 10px;
    //height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $c-scroll-bar;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #F0F0F0;
    border-radius: 25px;
  }

  &::-webkit-resizer,
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-corner { display: none; }
}