.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #00000040;

  .thead {
    border-radius: 8px 8px 0 0;
    background-color: #cdeafb;
    tr {
      border-radius: 8px 8px 0 0;
      th {
        cursor: pointer;
        white-space: nowrap;
        padding: 16px 12px;
        &:first-child {
          border-radius: 8px 0 0 0;
        }
        &:last-child {
          border-radius: 0 8px 0 0;
        }
        svg {
          margin-left: 2px;
          transition: transform 150ms linear;
          &.reversed {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .tbody {
    tr {
      &:nth-child(2n - 1) {
        background-color: #f6fbff;
      }
      td {
        padding: 12px;
      }
    }
  }
}
