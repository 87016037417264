@import "../../styles/index";

.jobs_wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 460px 1fr;
  grid-template-rows: 780px 50px;
  gap: 5px;
  max-width: 1290px;
  width: 100%;
  overflow: visible;

  @media screen and (max-width: $laptops-breakpoint) {
    grid-template-columns: 410px 1fr;
  }
}
