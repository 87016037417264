@import "../../../../styles/index";

.job_heading {
  //text-transform: uppercase;
  font-weight: 600;
  align-self: flex-start;
  direction: ltr;
  //width: 100%;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-details {

  &__card {

    .job-details__title {
      overflow: hidden;
      max-height: 25px;
      font-weight: 500;
      font-size: 20px;

      @media screen and (max-width: $tablets-landscape-breakpoint) {
        font-size: 18px;
      }
    }

    .info {

      &--item {
        position: relative;
        align-items: center;
        justify-content: left;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        padding-left: 20px;
        margin-bottom: 5px;
        direction: ltr;

        & > p {
          direction: ltr;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      div:last-child {
        margin-bottom: 0;
      }

      &__icon {
        position: absolute;
        left: 0;
        width: 15px;
        height: 15px;
        margin-right: 12px;
        //background-size: 16px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &__company {
        &--icon {
          background-image: url(../../../../assets/icons/company-icon.svg);
        }
      }

      &__location {
        &--icon {
          background-image: url(../../../../assets/icons/loc-icon.svg);
        }
      }

      &__price {
        &--icon {
          background-image: url(../../../../assets/icons/price-icon.svg);
        }
      }

      &__time {
        &--icon {
          background-image: url(../../../../assets/icons/clock-icon.svg);
        }
      }
    }
  }


  &__type--wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
  }

  &__type {
    display: flex;
    height: 19px;
    width: 74px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background: $c-part-time-btn;
    border-radius: 33px;
    &_full {
      background: $c-full-time-btn;
    }
    &_internship {
      background: #E4ACFF;
    }
    &_contract {
      background: #FFB016;
    }
  }
}
