.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 88888888;
  backdrop-filter: blur(4px);
  background-color: rgba(74, 88, 110, 0.4);
}