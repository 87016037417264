@import '../../../styles/index';

.purchase_history {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  .pagination {
    margin-bottom: 40px;
  }
}
