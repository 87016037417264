@import "../../../../styles/index";

.price_card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  height: 410px;
  padding: 50px 25px 40px;
  background: $c-white;
  box-shadow: 0 0 9.8px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  transition: 0.2s;
  overflow: hidden;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &_title {
      position: absolute;
      text-align: center;
      @include text (700, 28px, 34px, 0, $main-text-c);
    }

    .circle {
      transition: 0.3s;
    }

    .circle_one circle {
      fill: #DEDEDE;
    }

    .circle_two circle {
      fill: #FFE5BF;
    }

    .circle_three circle {
      fill: #FFD027;
    }

    .circle_four circle {
      fill: #FFAC4A;
    }

    .circle_five circle {
      fill: #C1CDFC;
    }
  }

  &:hover {
    transform: scale(1.05);

    //.circle > circle:first-child {
    //  transition: 0.9s;
    //  rotate: x 90deg;
    //}
  }

  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: $bg-industries-circle-active;
  }

  .description_list > li {
    list-style: inside;
    @include text (400, 14px, 17px, 0, $main-text-c);
    margin-bottom: 10px;

    & > span {
      font-weight: bold;
    }
  }

  .purchase {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 130px;

    &_price {
      display: block;
      @include text (600, 25px, 30px, 0, $main-text-c);

      &::after {
        position: absolute;
        content: "";
        top: 35px;
        left: 0;
        width: 100%;
        height: 1.6px;
        background-color: rgba(53,67,94,0.5);
      }
    }

    &_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 132px;
      height: 30px;
      margin-top: 20px;
      background-color: $main-button-c;
      border-radius: 20px;
      @include text (500, 16px, 16px, 0, $main-text-c);
    }
  }
}
