@import "../../../../styles/index";

.payment {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
  gap: 50px;

  @media screen and (max-width: $tablets-portait-breakpoint) {
    flex-direction: column-reverse;
  }

  &_package {
    display: flex;
    justify-content: center;

  }
}

.stripe {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  //border: 1px solid $main-employer-c;
  //border-radius: 10px;
  //padding: 15px;

  border: #f6f9fc solid 2px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 20px 50px -25px rgb(50 50 93 / 25%),
  5px 30px 60px -30px rgb(0 0 0 / 30%);
}

.payment_element {
  position: relative;
  padding: 0;
  //max-height: 250px;
}

.pay_btn {
  position: relative;
  width: 100px;
  height: 35px;
  border-radius: 5px;
  background-color: $main-button-c;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.15);
  }
}
