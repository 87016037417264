 @import '../../../styles/index';

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  min-width: min(80%, 800px);
  max-width: 1024px;
  padding: 0 0 3rem 0;

  @media (max-width: $tablets-landscape-breakpoint) {
    padding: 0 20px 3rem 20px;
  }

  @media (max-width: $mobile-landscape-breakpoint) {
    padding: 0 0 3rem 0;
  }

  .infoGridCard {
    //border-bottom: none;
  }

  .aloneGrid {
    border-bottom: 1px solid $main-employer-c;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;

    @media (max-width: $tablets-portait-breakpoint) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    .title {
      @include text(400, 16px, 20px, $_color: $main-candidate-c);
    }

    .title_red {
      @include text(400, 16px, 20px, $_color: $c-red);
    }
  }

  .dropdwn {
    @media (min-width: $tablets-portait-breakpoint) {
      align-self: flex-end;
    }

    &_select {
      margin-bottom: 0;
      width: 450px;

      @media (max-width: $tablets-portait-breakpoint) {
        width: 550px;
      }

      @media (min-width: $tablets-landscape-breakpoint) {
        width: 500px;
      }

      @media (max-width: 655px) {
        width: 350px;
      }
    }
  }

  .zero {
    text-align: center;
    color: $c-red;
    font-size: 15px;
    font-weight: 600;
  }

  .button_purchase {
    display: flex;
    justify-self: flex-end;
    background-color: $main-button-c;
    @include text(500, 16px, 20px);
    text-align: center;
    border-radius: 50px;
    padding: 11px 30px;
  }

  .job_info {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: 705px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    .job_types {
      @media (max-width: 705px) {
        width: 250px;
        align-items: center;
        gap: 20px;
      }

      .heading {
        align-self: flex-start;
        @include text(600, 15px, 19px);
        margin-bottom: 10px;
        margin-left: 5px;
      }

      .heading_error {
        color: $c-red;
      }

      .types_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;

        .checkbox {
          display: flex;
          justify-content: center;
          padding: 0 0 0 5px;
          width: 120px;
        }
      }
    }
  }
  .about {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 10px;
    label.description {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  }

  .form_btns {
    display: flex;
    gap: 20px;
    margin-top: 20px;

    .btn {
      @media (min-width: $tablets-landscape-breakpoint) {
        width: 200px;
        height: 44px;
      }
    }

    .submit_disabled {
      background-color: #cccccc;

      &:hover {
        box-shadow: none;
      }
    }

    .draft_btn {
      background-color: #3994D6;
      color: $c-white
    }
  }
}
