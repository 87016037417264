@import '../../../styles/utils/variables';

.container {
  position: relative;
  user-select: none;
  margin: 0 auto;
  min-width: min(80%, 800px);
  border-radius: 8px;
  margin-bottom: 120px;
  @media screen and (max-width: 1230px) {
    margin-bottom: 233px;
  }
  @media screen and (max-width: 867px) {
    margin-bottom: 368px;
  }
  .centered {
    text-align: center;
  }

  .invoiceInfo {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 2rem;

    & > :first-child {
      flex-grow: 1;
      input {
        width: 100%;
      }
    }
    // & > :not(:first-child) input {
    //   width: 150px;
    // }

    .datePickerContainer {
      & > label:first-child {
        user-select: none;
        cursor: pointer;
        width: fit-content;
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: $main-text-c;
        margin-left: 1.25rem;
        margin-bottom: 0.5rem;
      }
      & > label:last-child {
        width: 150px;
        padding: 9px 10px 8px 20px;
      }
    }
  }

  .table {
    margin-bottom: 12px;
    th {
      cursor: default !important;
      &:last-child {
        padding: 0;
      }
    }

    tbody tr {
      td {
        padding: 4px;
        text-align: center;
        &:last-child {
          padding: 4px 8px;
        }
        input {
          width: 100%;
          padding: 12px 8px;
          //border: none;
          outline: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          border: 1px solid transparent;

          -moz-appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &:focus {
            border: 1px solid lightblue;
          }
        }
        span {
          // font-family: monospace;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          margin-right: 0.3ch;
          & ~ input {
            width: 6ch;
            max-width: calc(100% - 1.3ch);
            padding: 12px 0px;
            text-align: left;
          }
        }
        button {
          cursor: pointer;
          padding: 0 5px 1px;
          border: none;
          border-radius: 4px;
          line-height: 16px;
          font-weight: bold;
          background-color: $bg-heading-pink-tone;
          color: white;
          &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
          }
        }
      }
    }
  }

  .greenButton {
    background-color: $main-green;
  }

  .paymentInfo {
    width: fit-content;
    margin-left: auto;
    text-align: right;
    padding: 0 8px;

    hr {
      height: 2px;
      margin: 12px 0;
      border: none;
      background-color: $main-text-c;
    }
    .subtotal {
      margin-bottom: 6px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
    .tax {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
    .total,
    .total span {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
    }
    .total {
      font-size: 22px;
      line-height: 26px;
      font-weight: 400;
      // margin-bottom: 4px;
    }
  }

  .submitButton {
    float: right;
    // margin-left: auto;
    margin-top: 16px;
    padding: 12px 48px;
    height: unset;
    width: fit-content;
  }
}
