@import "../../../styles/index";

.dropdown {
  margin-bottom: 10px;
  width: 272px;

  @media screen and (min-width: $tablets-landscape-breakpoint) {
    width: 242px;
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &__heading {
    margin-bottom: 2px;
    margin-left: 14px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: $main-text-c;
  }

  &__wrapper {
    position: relative;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    box-sizing: border-box;
  }

  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 7px 0 17px;
    justify-content: space-between;
    position: relative;
    outline: none;
    cursor: pointer;
    overflow-wrap: break-word;

    //overflow: hidden;
    //white-space: nowrap;
    //text-overflow: ellipsis;

    @include hover(border-color, $input-border-c, 0.2);

    height: 37px;
    background-color: $c-white;
    border: 0.5px solid $input-border-c;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
    border-radius: $input-radius;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: $main-no-active-text-c;

    //@media screen and (max-width: $mobile-landscape-breakpoint) {
    //  width: 131px;
    //  font-size: 13px;
    //}

    &:focus {
      border-color: $input-border-c;
    }
  }

  &__text {
      white-space: nowrap;
      overflow: hidden;
      color: #000;
  }

  &__list {
    width: 100%;
    margin-top: 2px;
    border-radius: 10px;
    position: absolute;
    border: 1px solid $input-border-c;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
    background-color: white;
    box-sizing: border-box;
    z-index: 10;
    transform: translateY(-5px);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s, transform 0.2s;

    &--is-open {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__list-item {

  }

  &__icons {
    display: flex;
    align-items: center;

    .select__icon {
      width: 24px;
      height: 24px;
      border-radius: 50px;
      background-color: #F5F7FF;
      background-repeat: no-repeat;
      background-position: center;

      &--jobs {
        background-image: url("../../../assets/icons/select-clock.svg");
      }
      &--location {
        background-image: url("../../../assets/icons/select-location.svg");
      }
      &--industries {
        background-image: url("../../../assets/icons/select-industries.svg");
      }
      &--education {
        background-image: url("../../../assets/icons/education.svg");
      }
    }
  }

  &__arrow {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    background-image: url("../../../assets/icons/dropdown-arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.2s;

    &--up {
      transform: rotate(180deg);
    }
  }
}
