@import "../../styles/index";

.industries {
  margin-bottom: 80px;

  .heading {
    margin-bottom: 30px;
    //margin-left: 60px;
    @include text (600, 27px, $_color: $main-text-c);

    span {
      color: $main-candidate-c
    }
  }

  &_list {
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(6, 180px);
    justify-content: center;
    grid-gap: 10px;

    @media screen and (max-width: $laptops-breakpoint) {
      display: grid;
      grid-template-columns: repeat(4, 180px);
      grid-gap: 25px;
    }

    @media screen and (max-width: 900px) {
      display: grid;
      grid-template-columns: repeat(3, 180px);
      grid-gap: 30px;
    }
  }

  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    width: 180px;
    height: 170px;
    padding: 25px;
    overflow: hidden;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transform: translateY(-8px);
      background-color: #FFECD6;
      box-shadow: 0 4.5px 5.8px rgba(0, 0, 0, 0.15);
    }

    &:hover .circle_wrapper::after {
      background-color: $bg-industries-circle-active;
    }

    &:hover .bg_circle {
      background-color: $bg-industries-circle-active;
    }

    .industrie_heading {
      text-align: center;
      @include text (600, 16px, $_color: $main-text-c);
    }

    .circle_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 75px;
      height: 75px;

      &::after {
        position: absolute;
        content: "";
        top: -5px;
        left: -5px;
        width: 10px;
        height: 10px;
        background-color: $bg-industries-circle;
        border-radius: 50px;
        transition: 0.2s;
      }
    }

    .bg_circle {
      position: absolute;
      width: 75px;
      height: 75px;
      border-radius: 50px;
      background-color: $bg-industries-circle;
      transition: 0.2s;
    }
  }

  .industrie_img {
    position: relative;
    display: block;
    //width: 50px;
    //height: 50px;
    //bottom: -5px;
    width: 80%;
    //height: 85%;
  }
}
