@import '../../styles/utils/variables';

.container {
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: auto;
  padding: 15px 25px;
  width: 420px;
  height: 200px;
  border-radius: 6px;


  background-color: #f1f1f1;
  cursor: pointer;
  direction: ltr;
  transition: 0.1s;

  //max-height: 250px;
  //height: 100%;

  .info {
    margin-top: 8px;
    margin-bottom: 12px;
    gap: 0.5rem;
    width: 100%;

    .additional {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 10px;
      .id {
        position: absolute;
        right: 0;
        top: -5px;
        margin-top: 5px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &:hover {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
  }

  &.active {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    overflow: hidden;

    &::after {
      position: absolute;
      content: '';
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 5.5px;
      background-color: $main-green;
      transition: 0.2s;
    }
  }
}
