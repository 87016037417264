@import '../../../../styles/index';

.payment_info {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    max-width: 400px;
    width: 100%;
    height: 100%;

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: flex-start;
        gap: 8px;

        .value {
            text-align: center;
            font-size: 1.4rem;
            font-weight: 600;
        }

        .title {
            text-align: center;
            font-size: 1rem;
        }

        .btn {
            width: 115px;
            height: 35px;
            padding: 0;
            font-weight: 500;
            font-size: 14px;
            background-color: $main-button-c;
            transition: $anim-time;

            &:hover {
                box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.15);
            }
        }
    }
}