@import "../../../styles/index";

.auth {

  &__phone {
    margin-bottom: 30px;
  }

  &__login {
    display: flex;
    flex-direction: column;
    width: 240px;
    padding-top: 40px;
  }

  &__error {
    color: $c-red;
  }

  &__submit {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }

  &__register {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    span {
      margin-right: 3px;
    }
  }
}
