@import "../../../styles/index";

.auth {

  &__title {
    @include text (500, 15px, 18px, $_color: $main-text-c);
    margin-bottom: 5px;
  }

  &__phone {
    width: 236px;

    &--code {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #EFEFEF;
      border-radius: 5px;
      margin-right: 5px;
      padding: 4px;

      span {
        @include text (400, 18px, $_color: $main-text-c);
      }
    }

    &--input {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__forgot-pass {
    display: block;
    @include text (500, 14px, $_color: $main-text-c);
    padding: 5px 0;
    margin-top: 3px;
    align-self: flex-end;
    cursor: pointer;

    //make hover effect
    &:hover {
      text-decoration: underline;
    }
  }

  &__register-link {
    background-color: transparent;
    display: inline-block;
    border: none;
    padding: 5px 0;
    @include text (700, 14px, $_color: $main-text-c);

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
