@import '../../styles/index';

.tabs_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 1.5rem 0;

  @media screen and (max-width: $breakpoint-for-employer-header) {
    background-color: $main-employer-c;
    margin-bottom: 25px;
  }

  .tab {
    height: 42px;
    width: 200px;
    span {
      font-size: 16px;
    }

    &:global(.active) {
      background-color: $main-button-c;
      & > span {
        font-weight: 500;
      }
    }
    &:not(:global(.active)) {
      background-color: $main-no-active-button-c;
    }
  }
}

.completion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding: 0 20px;
  margin: 150px 0;

  .heading { text-align: center }

  .back_btn {
    padding: 10px 30px;
    max-width: none;
    height: unset;
    width: max-content;
    font-size: 18px;
    transition: .2s;

    &:hover {
      box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.15);
    }
  }
}