@import '../../../styles/utils/variables';

.empty {
  display: flex;
  justify-content: center;
}

.container {
  position: relative;

  width: 100%;
  max-width: 1300px;
  min-height: 55vh;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 800px;
  gap: 0.5rem;
  padding: 0.5rem 2rem 0;
  align-self: center;
  margin: 0 20px 180px;

  @media screen and (max-width: 1230px) {
    margin-bottom: 253px;
  }
  @media screen and (max-width: 867px) {
    margin-bottom: 368px;
  }

  .left_menu {
    //height: 100%;
  }

  .pagination {
    padding: 10px 0;
  }

  .search {
    margin-bottom: 24px;
  }
  .orderBy {
    margin-bottom: 12px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 12px;

    & > .btn {
      padding: 10px 32px;
      height: unset;
      width: 180px;
      &.delete {
        background-color: $c-danger;
        color: #fff;
      }
      &.reject {
        background-color: #fff;
        border: 1px solid $main-text-c;
        color: $main-text-c;
      }
    }
  }
}
