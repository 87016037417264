@import "../../../styles/index";

.registration {
  padding: 30px 25px;
  width: 100%;

  &__input {
    margin-bottom: 20px;
  }

  &__input--cntr {
    display: flex;
    align-items: center;

    .registration__phone--code {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 26px;
      background-color: #EFEFEF;
      border-radius: 5px;
      margin-right: 5px;

      span {
        @include text (400, 18px, $_color: $main-text-c);
      }
    }
  }

  &__notify {
    display: flex;
    flex-direction: column;

    &--title {
      @include text (500, 15px, $_color: $main-text-c);
      margin-bottom: 20px;
    }

    &--list {
      display: flex;
      justify-content: space-between;
    }
  }

  &__submit {
    display: flex;
    justify-content: center;
  }

  &__error {
    color: $c-red;
    display: block;
    text-align: center;
    margin-bottom: 10px;

    .login_link {
      position: relative;
      display: inline;
      font-weight: 500;
      cursor: pointer;

      &::after {
        position: absolute;
        content: "";
        bottom: -1px;
        left: 0;
        width: 0;
        height: 2px;
        //background-color: $main-employer-c;
        background-color: $c-red;
        border-radius: 10px;
        transition: width 0.1s;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }
}
