@import "../../../styles/index";

.header_wrap {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: $main-employer-c;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}

.header_mob_wrap {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: $c-white;
  border-bottom: 1px solid #3653B7;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);

  .header_mob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: $header-mob-h;
    padding: 0 20px;
  }
}

.header {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 1600px;
  height: 64px;
  min-height: $header-mob-h;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 0 auto;


  .logo {
    position: absolute;
    left: 20px;

    svg path {
      fill: #fff;
    }
    h2 {
      color: #fff;
    }
  }
  &_noauth {
    display: flex;
    position: relative;
    justify-content: flex-end;
  }
  &_auth {
    display: flex;
    justify-content: center;
    position: relative;

    @media screen and (max-width: $breakpoint-for-employer-header) {
      justify-content: flex-end;
    }

    .auth_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 25px;
      height: 100%;

      @media screen and (max-width: $laptops-breakpoint) {
       padding-right: 85px;
      }

      @media screen and (max-width: $tablets-landscape-breakpoint) {
        padding-right: 130px;
      }

      @media screen and (max-width: $breakpoint-for-employer-header) {
        padding-right: 20px;
      }
    }

    .logo {
      //position: absolute;
      //left: 0;
    }
    .center_menu {
      max-height: 30px;
      display: flex;
      gap: 25px;
    }
    .buttons_wrapper {
      position: absolute;
      right: 20px;
      top: calc(50% - 17px)
    }
  }
  .not_auth_menu_wrapper {
    display: flex;
    gap: 30px;
    justify-self: flex-end;
  }
}

.search_btn {
  background-color: $main-button-c;
}
.login_btn {
  background-color: $secondary-e;
}
.link {
  span {
    color: #fff !important;
  }
  &::after {
    background-color: #fff !important;
  }
  svg path {
    fill: #fff !important;
  }
}
.buttons_wrapper {
  display: flex;
  gap: 12px;
  .logout {
    background: transparent;
    color: #fff;
    width: 80px;
    svg path {
      fill: #fff;
    }
  }
}
