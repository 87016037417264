@import '../../../../styles/index';

.pending_approval {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
  padding: 0 20px;

  .mob_job_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
    margin-bottom: 15px;
  }
}
