@import "../../../styles/index";

.password_modal {
  padding: 45px 60px 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .password_fields {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;
  }
  .error {
    color: $c-red;
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
}