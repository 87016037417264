@mixin text ($_weight, $_size, $_l-height: 18px, $l-spacing: 0, $_color: #35435E) {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: $_weight;
  font-size: $_size;
  line-height: $_l-height;
  letter-spacing: $l-spacing;
  color: $_color;
}

@mixin hover($_property, $_toValue, $_time) {
  transition: #{$_property} #{$_time}s;

  &:hover {
    #{$_property}: $_toValue;
  }
}
