.quality {
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  //overflow-wrap: break-word;

  .whatsapp_link {
    color: #35435e;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  & > b {
    margin-right: 4px;
    font-weight: 600;
    //white-space: nowrap;
  }

  & > p {
    font-weight: 400;
    //overflow-wrap: break-word;
  }
}

.experience {
  font-weight: 400;
  & > p {
    //overflow-wrap: break-word;
    white-space: pre-wrap;
  }
}