@import '../../styles';

.work_full_info {
  padding: 0 25px 20px;
  background: #ffffff;
  box-shadow: 0 0 23px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  max-width: 800px;
  width: 100%;
  height: 98%;
  overflow-y: scroll;


  @extend %scroll;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 0 23px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  width: 100%;
  height: 95%;
}

.label {
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
}

.work_info_wrap {
  border-bottom: 0.5px solid rgba(21, 26, 37, 0.5);
  padding: 15px 0;
  position: relative;

  &.details {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .postedBy {
      flex-grow: 1;
      padding-left: 24px;
      border-left: 1px solid rgba(#35435e, 0.5);
    }
  }

  .status {
    display: flex;
    font-weight: 500;
    margin-bottom: 5px;

    & > p {
      color: #00ac45;
      padding-left: 4px;
    }
  }
}

.work_info_wrap:last-child {
  border-bottom: none;
}

.work_info_main {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  & > span {
    right: 0;
  }
  &_text {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
}


.work_info_type {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.work_info_buttons {
  display: flex;
  gap: 10px;

  .edit {
    width: 110px;
    padding: 0;
    height: 32px;
    background-color: #f5f7ff;
    border: 1px solid $main-blue;

    span {
      color: $main-blue;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .btn_bump {
    width: 110px;
    background-color: $main-employer-c;
    color: $c-white;
    font-size: 14px;
  }

  .save_btn {
    color: #3653b7;
    background-color: #fff;
    border: 1px solid #3653b7;
    &_saved {
      background-color: #00caba;
      color: #35435e;
      border-color: #35435e;
    }
  }
}

.typeAndDate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  .id {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    margin-top: 15px;
  }
}
