@import '../../../../../styles/index';

.posted_list {
  //position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  overflow-x: visible;
  direction: rtl;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 5px;

  @extend %scroll;

  .job_card {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 430px;
    min-height: 200px;
    max-height: 210px;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding: 15px 25px;
    border-radius: 6px;
    //box-shadow: 0 0 23px rgba(0, 0, 0, 0.15);
    background-color: #F1F1F1;
    direction: ltr;
    transition: 0.1s;
    cursor: pointer;

    @media screen and (max-width: $laptops-breakpoint) {
      width: 380px;
    }

    &:hover {
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
    }

    .footer {
      position: relative;
      margin-top: 8px;
      display: flex;
      flex-direction: column;

      .status {
        position: absolute;
        right: 0;
        top: -8px;
      }

      &_info {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
      }
    }
  }

  .job_card_active {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF !important;
  }
}
