.qualification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  & > p:first-child {
    margin-bottom: 5px;
  }

  &--title {
    font-size: 15px;

    span {
      font-weight: 400;
    }
  }
}