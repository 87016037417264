@import "../../styles/index";

.info-grid {

  &__card {
    position: relative;
    border: 1px solid $info-grid-card-bc;
    border-bottom: none;
    background-color: #F8FDFF;
    width: 100%;
    max-width: 930px;
    padding: 50px 40px;
    @media (max-width: $mobile-landscape-breakpoint) {
      padding: 42px 15px;
    }

    &--top {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      @media (max-width: $mobile-landscape-breakpoint) {
        border-radius: 0;
      }
    }

    &--bottom {
      @media (min-width: $mobile-landscape-breakpoint) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      border-bottom: 1px solid $info-grid-card-bc;
    }

    &--candidate {
      border-color: $main-candidate-c;
      @media (max-width: $mobile-landscape-breakpoint) {
        border-left: none;
        border-right: none;
      }
    }

    &--employer {
      border-color: $main-employer-c;
      padding: 35px 50px;

      @media (max-width: $mobile-landscape-breakpoint) {
        width: 100%;
        padding: 40px 20px;
        border-radius: 0;
        border-left: none;
        border-right: none;
      }
    }
  }

  &__heading {
    position: absolute;
    top: -13px;
    left: 25px;
    display: flex;
    align-items: center;
    box-sizing: content-box;
    height: 24px;
    padding: 1px 9px 0;
    background-color: #FFBCBC;
    border-radius: 5px;
    font-size: 15px;
    line-height: 18px;
    background-size: 11px;
    background-repeat: no-repeat;
    background-position: 8px 50%;
    gap: 9px;

    path {
      fill: $main-blue;
    }

    span {
      @include text (500, 16px, 20px, $_color: $main-text-c);
    }
  }
}
