@import "../../../styles/index";

.go_back {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
  outline: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px 20px;
  background-image: url("../../../assets/icons/left-arrow.svg");
}
