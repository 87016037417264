@import '../../../styles/index.scss';

.input_wrapper {
  display: flex;
  gap: 6px;
  position: relative;
  flex-grow: 1;

  .phone_code {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    width: 45px;
    padding: 5px 0;
    margin-bottom: 3px;
    color: #595959;
    font-size: 18px;
    border-radius: 4px;
    background: #EFEFEF;
    font-weight: 400;
  }
  .input_button {
    position: absolute;
    top: -4px;
    right: 0;
    border: 1px solid $main-blue;
    background: #fff;
    border-radius: 62px;
    width: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $main-blue;
    font-size: 14px;
    font-weight: 500;
    height: 25px;
    cursor: pointer;
    transition: $anim-time;
    &:hover {
      color: $c-white;
      background: $main-blue;
    }
  }

  .icon {
    position: absolute;
    right: 0;
    bottom: 6px;
  }
}

