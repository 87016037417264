@import '../../../../styles/index';

.details_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 820px;
  min-width: 400px;
  width: 100%;
  background-color: $c-white;
  border-radius: 6px;
  box-shadow: 0 0 23px rgba(0, 0, 0, 0.15);
  padding: 25px 30px;
}

.details {
  display: flex;
  flex-direction: column;
  max-width: 820px;
  min-width: 400px;
  width: 100%;
  background-color: $c-white;
  border-radius: 6px;
  box-shadow: 0 0 23px rgba(0, 0, 0, 0.15);
  padding: 25px 30px;
  overflow-y: scroll;


  @extend %scroll;

  .header {
    display: flex;
    //justify-content: space-between;
    gap: 5px;


    @media screen and (max-width: 1250px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &_main {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex-grow: 1;
    }

    &_btns {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      @media screen and (max-width: 1250px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      }

      .id {
        text-align: end;
        margin-bottom: 10px;
        font-size: 14px;
        color: #35435E;
      }

      .btns {
        display: flex;
        gap: 10px;
      }

      .btn_edit {
        width: 110px;
        background-color: #f5f7ff;
        border: 1px solid $main-blue;

        span {
          color: $main-blue;
          font-size: 14px;
        }
      }

      .btn_bump {
        width: 110px;
        background-color: $main-employer-c;
        color: $c-white;
        font-size: 14px;
      }
    }
  }

  .payment_info_wrapper {
    display: flex;
    padding: 15px 0;
    margin: 15px 0;
    border-top: 0.5px solid rgba(21%, 26%, 37%, 0.5);
    border-bottom: 0.5px solid rgba(21%, 26%, 37%, 0.5);

    .qualification {
      margin: auto 0;
      flex-grow: 1;
    }

    .payment_info {
      flex-grow: 1.5;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-left: 5px;
      border-left: 0.5px solid rgba(21%, 26%, 37%, 0.5);
    }
  }

  &_header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
}