@import "../../../styles/index";

.work_full_info {
  padding: 0 25px;
  background: #FFFFFF;
  box-shadow: 0 0 23px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  width: 100%;

  overflow-y: scroll;
  overflow-x: visible;

  @extend %scroll;
}

.work_info_wrap {
  border-bottom: 0.5px solid rgba(21, 26, 37, 0.5);
  padding: 20px 0;
  position: relative;

  &_padding {
    border-bottom: none;
  }
}

.work_info_wrap:last-child {
  border-bottom: none;
}

.work_info_main {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  & > span {
    right: 0;
  }
  &_text {
    display: flex;
    gap: 12px;
    flex-direction: column;
    max-width: 520px;
  }
}

.work_info_type {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.work_info_buttons {
  display: flex;
  gap: 10px;

  button {
    font-size: 16px;
  }

  .save_btn {
    color: #3653b7;
    background-color: #fff;
    border: 1px solid #3653B7;
    &_saved {
      background-color: #00caba;
      color: #35435e;
      border-color: #35435e;
    }
  }
}

.typeAndDate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
