@import '../../../../styles/index';

.table_wrap {
  display: flex;
  max-width: 995px;
  width: 100%;
  margin: 35px;
  border-radius: 15px;
  overflow: hidden;
}

.table {
  width: 100%;
  border-collapse: collapse;

  &_thead {
    height: 52px;
    background-color: #A4E7FC;

    & > td {
      @include text(500, 16px, 20px);
      text-align: center;
    }
  }

  &_body {
    width: 100%;
    max-height: 520px;

    & > tr {
      height: 55px;
    }

    & > tr:nth-child(odd) {
      background-color: #F7FBFF;
    }

    & > tr > td {
      @include text(400, 16px, 20px);
      text-align: center;
    }
  }

  .download_btn {
    width: 110px;
    height: 25px;
    @include text(500, 13px, 15px);
    margin: 0;
  }
}
