.description {
  font-size: 15px;
  padding-bottom: 20px;

  &--text {
    margin-top: 15px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    white-space: pre-wrap;
  }
}