@import "../../../../styles/index";

.works_steps {
  background-color: $c-white;
  padding: 70px 0;

  .heading {
    @include text(700, 27px, 33px);
    margin-bottom: 30px;
  }

  .steps_list {
    display: flex;
    gap: 30px;

    @media screen and (max-width: 967px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.step_card {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 285px;
  height: 300px;
  padding: 25px 50px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  transition: $anim-time;

  &:hover {
    transform: scale(1.05);
  }

  .title {
    @include text(700, 20px, 25px, $main-text-c);
  }

  .main_img {
    width: 100px;
    height: 100px;
  }

  .text {
    display: block;
    position: relative;
    height: 55px;
    width: 100%;
    text-align: center;
    vertical-align: bottom;
    @include text(700, 20px, 25px, $main-text-c);

    & > span {
      align-self: flex-end;
      color: #3653B7;
    }

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: -10px;
      width: 100%;
      height: 1.6px;
      background-color: rgba(53,67,94,0.5);
    }
  }

  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: $bg-industries-circle-active;
  }
 }
