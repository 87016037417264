@import "../../styles/index";

.tg-channels {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    user-select: none;
    @include text (600, 27px, $_color: $main-text-c);
    font-style: normal;
    text-align: center;

    @media screen and (min-width: $mobile-landscape-breakpoint) {
      @include text (600, 27px, $_color: $main-text-c);
      flex-direction: row;
    }

    span {
      display: block;
    }

    &--span {
      color: $main-candidate-c;
    }
  }

  &__list {
    display: flex;
    gap: 30px;
    margin: 40px 0 50px;

    @media screen and (max-width: 720px) {
      flex-direction: column;
    }

    &--qr {
      width: 170px;
      height: 170px;
      //padding: 12.5px;
      filter: drop-shadow(0px 0px 13.5494px rgba(0, 0, 0, 0.25));
      border-radius: 10px;
      margin-bottom: 15px;
      background-color: $c-white;

      @media screen and (max-width: 720px) {
        display: none;
      }
    }

    &--link {
      display: flex;
      align-items: center;
      width: 170px;
      height: 40px;
      background: #1D9FE8;
      border-radius: 8.5px;
      color: $c-white;
      font-weight: 500;
      font-size: 16px;
      user-select: none;

      @include hover(filter, drop-shadow(0px 5.12975px 5.12975px rgba(0, 0, 0, 0.25)), 0.2)
    }
  }

  &--icon {
    width: 30px;
    height: 30px;
    margin: 0 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../assets/icons/telegram-airplane-icon.svg");
  }
}
