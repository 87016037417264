@import "../../../styles/index";

.link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  padding: 0;
  border: none;
  border-radius: 57px;
  width: 145px;
  height: 35px;
  @include text (700, 13px, 18px, $_color: $main-text-c);

  &:hover {
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.15);
  }
}

.link__partTime {
  background-color: $c-part-time-btn;
}

.link__fullTime {
  background-color: $c-full-time-btn
}
