@import "../../styles/index";

.layout {
  min-height: 100vh;
  min-width: 90vw;
  height: 100%;
  position: relative;

  &_content {
    padding-bottom: 90px;
    padding-top: 64px;
    height: 100%;

    @media screen and (max-width: 1230px) {
      padding-bottom: 195px;
    }

    @media screen and (max-width: $tablets-portait-breakpoint) {
      padding-bottom: 320px;
    }
  }
}
