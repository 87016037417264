@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto-Bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto-Light.otf);
  font-weight: 300;
}

@font-face {
  font-family: Montserrat;
  src: url(../assets/fonts/Montserrat-ExtraBold.ttf);
  font-weight: 800;
}
