@import "../../../styles/index";

.work_card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 25px;
  max-width: 430px;
  width: 100%;
  min-height: 200px;
  max-height: 210px;
  border-radius: 6px;
  background-color: #F1F1F1;
  cursor: pointer;
  direction: ltr;
  transition: 0.1s;

  @media screen and (max-width: $laptops-breakpoint) {
    width: 380px;
  }

  &:hover {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
  }
}

.work_card_active {
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
  overflow: hidden;

  &::after {
    position: absolute;
    content: "";
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 5.5px;
    background-color: $main-green;
    transition: 0.2s;
  }
}

.work_list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
  overflow-y: scroll;
  overflow-x: visible;
  direction: rtl;
  padding-top: 5px;

  @extend %scroll;
}

.work_list_mob {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
