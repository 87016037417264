@import "../../styles/index";

.visitor {
  background-color: $main-candidate-c;
  padding-bottom: 24px;

  &__header {
    padding: 30px 0;
  }

  &__header-heading {
    display: flex;
    flex-direction: row;

    .heading {
      user-select: none;
      @include text (700, 30px, 37px, $_color: $main-text-c);
    }

    .heading-white {
      color: $c-white;
    }
  }
}
