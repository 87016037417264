@import "../../../styles/index";

.card__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.view_all {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $main-blue;
  border-radius: 20px;
  background-color: $c-white;
  width: 85px;
  height: 23px;
  margin: 15px auto 50px;
  color: $main-text-c;
  transition: .3s;

  &:hover {
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.15);
  }
}
