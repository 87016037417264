@import "../../../styles/index";

.drawer_link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 48px;
  padding-left: 70px;
  @include text(500, 18px, 20px);
  background-color: rgba(212,245,243,0.3);

  &_active {
    background-color: #D4F5F3;
  }

  .icon {
    width: 22px;
    height: 20px;
    margin-right: 8px;
  }
}
