@import '../../../styles/utils/variables';

.container {
  position: relative;
  user-select: none;
  margin: 0 auto;
  min-width: min(80%, 800px);
  width: 100%;
  max-width: 1024px;
  padding: 2rem 1.5rem;
  border: 1px solid $main-admin-c;
  border-radius: 8px;
  margin-bottom: 120px;
  @media screen and (max-width: 1230px) {
    margin-bottom: 233px;
  }
  @media screen and (max-width: 867px) {
    margin-bottom: 368px;
  }
  .centered {
    text-align: center;
  }
  .pagination {
    margin-top: 24px;
    margin-left: auto;
  }
}
