@import "../../../styles/index";

.profile_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
  min-width: 360px;
  max-width: 700px;

  @media (max-width: $tablets-portait-breakpoint) {
    padding: 0 20px;
  }

  @media (max-width: $mobile-landscape-breakpoint) {
    padding: 0;
  }

  .profile_grid {
    border-radius: 10px;
    border-bottom: 1px solid $main-employer-c;

    @media (max-width: $mobile-landscape-breakpoint) {
      border-radius: 0;
    }
  }

  .inputs_wrapper {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .notify_form_group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 35px auto 0;
    max-width: 500px;

    .notify_heading {
      display: block;
      padding: 0 10px;
      text-align: center;
      @include text(500, 16px, 20px);
    }

    .notify_list {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media (max-width: $mobile-landscape-breakpoint) {
        padding: 0 20px;
      }
    }
  }

  .profile_buttons {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    gap: 20px;

    .delete_btn {
      background: $c-red;
      color: #fff;
      width: 180px;
      height: 42px;
      @media (max-width: 465px) {
        width: 140px;
      }
    }

    .save_btn {
      width: 180px;
      height: 42px;
      @media (max-width: 465px) {
        width: 140px;
      }
    }
  }
}
