@import '../../../../styles/index';

.accordion {

  .arrowSvg {
    width: 30px;
    height: 30px;
    transition: all 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }

    & > path {
      fill: $main-employer-c;
    }
  }
}