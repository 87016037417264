@import "../../../styles/index";

.field {
  padding: 4px 4px 4px 14px;
  border: 0.5px solid #86A1FF;
  border-radius: 57px;
  background-color: #fff;
  box-shadow: 0 0 13.2101px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    border: none;
    outline: none;
    font-size: 16px;
    //font-weight: 700;
    color: $main-blue;
    width: 100px;
  }
  .calendar_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 23px;
    border-radius: 100%;
    background: #F5F7FF;
  }
}