@import "../../../styles/index";

.candidate {
  height: 100vh;
}

.header__wrap {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;
  background-color: $c-white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}

.header {
  display: flex;
  width: 1385px;
  flex-direction: row;
  background-color: #FFF;
  min-height: $header-mob-h;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .logo {
    //position: absolute;

    &__image {
      background: url("../../../assets/icons/Logo.svg") no-repeat center;
      background-size: 32px;
      height: 32px;
      width: 32px;
    }
  }
}
