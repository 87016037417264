@import "../../../styles/index";

.work__card {
  position: relative;
}

.card {
  display: flex;
  flex-direction: column;
  width: 320px;
  max-height: 310px;
  border-radius: 8px;
  background-color: $c-white;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.25);
  padding: 18px;
  margin-bottom: 20px;

  &__term {
    align-self: flex-end;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #595959;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__header {
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    margin-bottom: 12px;
  }

  &__title {

  }

  &__buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    justify-self: flex-end;

    button {
      font-size: 16px;
    }
  }

  &__date {
    //position: absolute;
    //bottom: 20px;
    //right: 20px;
    font-weight: 400;
    font-size: 12px;
    color: #595959;
  }
}

.card-desk {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 110px;
  padding: 25px 30px 15px;
  border-radius: 8px;
  background-color: $c-white;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  margin-bottom: 15px;

  @include hover(background-color, #FFECD6, 0.2);

  &::before {
    transition: 0.2s;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 6px;
    height: 100%;
  }

  &:hover {
    &::before {
      background-color: $main-blue;
    }
  }

  &__title {
    @include text (500, 20px, 29px);

    &:first-letter {
      text-transform: uppercase;
    }

    @media screen and (max-width: 1000px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    //height: 65px;

    @include text (400, 16px);
    div {
      display: flex;
      align-items: center;
    }

    .new-job {
      position: absolute;
      top: 5px;
      left: 30px;
      @include text (400, 16px, 24px, 0, $main-candidate-c);
      font-style:italic;
    }
  }

  &__interaction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //align-items: flex-end;
    width: 100%;

    .interaction__wrap {
      display: flex;
      align-items: center;

      .card-desk__location {
        display: flex;
        align-items: center;

        &--icon {
          width: 15px;
          height: 15px;
          margin-right: 5px;
          margin-left: 10px;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(../../../assets/icons/loc-icon.svg);
        }
      }
    }
  }

  &__price {
    @include text (500, 20px, 32px);
  }

  &__buttons {
    button {
      font-size: 16px;
    }
    :first-child {
      margin-right: 5px;
    }
  }
}

