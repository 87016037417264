@import '../../../styles/index';

.container {
  margin: 0 auto;
  min-width: min(80%, 800px);
  width: unset;
  max-width: 1024px;
  padding: 3rem 0 136px 0;
  @media screen and (max-width: 1230px) {
    padding-bottom: 233px;
  }
  @media screen and (max-width: 867px) {
    padding-bottom: 368px;
  }

  .infoGridCard {
    border-color: $main-admin-c;
    .datePickerContainer {
      & > label:first-child {
        cursor: pointer;
        font-weight: 600;
        padding-left: 12px;
      }
      &>label:last-child {
        width: 242px;
        padding: 8px 12px;
        margin-top: 6px;
      }
    }
    &:not(:nth-child(2)) > div {
      margin-bottom: 0.875rem;
    }
    &:nth-child(2) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem 2rem;

      fieldset.jobType {
        border: none;
        padding-top: 0.75rem;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1rem;

        legend {
          align-self: flex-start;
          @include text(600, 15px, 19px);
          padding-left: 10px;
        }

        label {
          width: fit-content;
          user-select: none;
          position: relative;

          cursor: pointer;
          * {
            cursor: pointer;
          }

          span {
            padding: 0.375rem 1rem;
            border-radius: 3rem;
            color: $main-text-c;
            background-color: $bg-unselected-point;
            font-weight: 700;
          }

          input {
            position: absolute;
            inset: 0;
            opacity: 0;
            visibility: hidden;

            &:checked + span {
              background-color: $bg-selected-point;
              color: $c-white;
            }
          }
        }
      }
    }
    .salary {
      margin: 15px 0;
    }
    //label.salary {
    //  display: flex;
    //  margin-top: 1.25rem;
    //  margin-bottom: 1rem;
    //
    //  input {
    //    margin: 0 0.25rem;
    //    max-width: 3.5rem;
    //    text-align: center;
    //  }
    //}
    label.description {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      textarea {
        font-size: 0.9375rem;
        resize: none;
        padding: 0.5rem 0.75rem;
        // max-height: 600px;
      }
    }
    span.title {
      font-weight: 700;
      color: $main-text-c;
    }
  }

  footer.buttons {
    width: 100%;
    margin-top: 2rem;

    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;

    button {
      cursor: pointer;
      //padding: 0.75rem 4rem;
      border: none;
      width: 200px;
      height: 45px;
      &:first-child {
        background-color: #f32a2a;
        color: $c-white;
      }
      &:last-child {
        background-color: $main-button-c;
        color: $main-text-c;
      }
    }
  }
}
