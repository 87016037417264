@import '../../styles/utils/_variables.scss';

.wrap {
  z-index: 10;
  position: sticky;
  inset: 0;
  top: 0;
  width: 100%;
  background-color: $main-admin-c;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}

.header {
  max-width: 1600px;
  width: 100%;
  height: 64px;
  padding: 0 2rem;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.5rem;

  & > span {
    user-select: none;
    padding: 0.375rem 0.75rem;
    border: 1px solid #fff;
    font-family: 'Montserrat', sans-serif;
    color: $bg-industries-circle-active;
  }

  & > nav {
    display: flex;
    gap: clamp(1rem, 4.5vw - 0.75em, 2.5rem);
    .link {
      span {
        color: #fff !important;
      }
      &::after {
        background-color: #fff !important;
      }
      svg path {
        fill: #fff !important;
      }
    }
  }

  & > button {
    font-weight: 400;
    color: #fff;

    &::after {
      background-color: #fff !important;
    }
    svg path {
      fill: #fff !important;
    }
  }
}
