@import "../../../styles/index";

.modal {
  //width: 850px;
  //width: 100%;
  //overflow-y: hidden;

  //-ms-overflow-style: none;
  //scrollbar-width: none;
  //
  //&::-webkit-scrollbar {
  //  width: 0;
  //  display: none;
  //}

  &_head {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    width: 100%;
    padding: 0 10px;
    background-color: $main-candidate-c;

    .heading {
      @include text (600, 15px);
      text-transform: uppercase;
    }

    .close {
      justify-self: flex-end;
      background-color: transparent;
      width: 18px;
      height: 18px;
      border: none;
      cursor: pointer;

      &_icon {
        width: 100%;
        height: 100%;
      }
    }
  }

  .job_info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 36px;

    @media screen and (max-width: 600px) {
      padding: 15px 25px;
    }

    &_head {
      display: flex;
      justify-content: space-between;
    }
  }

  form {
    background: #F5F7FF;
    padding: 15px 35px;

    @media screen and (max-width: 456px) {
      padding: 8px 15px;
    }

    .basic_form {
      display: grid;
      grid-template-rows: repeat(4, 1fr) 220px;
      justify-items: flex-start;
      gap: 5px;

      .salary {
        margin: 15px 0 0;
        align-items: center;

        @media screen and (max-width: 686px) {
          margin: 10px 0;
        }

        & > span {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .row_input {
        align-items: flex-end;
        width: 100%;
        padding-top: 0;
        gap: 6px;

        @media screen and (max-width: 686px) {
          align-items: flex-start;
          flex-direction: column;
          gap: 0;

          & > div {
            width: 100%;
          }
        }

        label {
          text-overflow: inherit;
          position: static;
          transform: none;
          font-size: 14px;
          font-weight: 500;
        }

        input {
          padding: 0;
        }
      }

      .select {
        display: flex;
        gap: 6px;
        align-items: flex-end;

        & > p {
          font-size: 14px;
          font-weight: 500;
        }

        &_box {
          & > div {
            padding: 0;

            & button {
              font-size: 14px;
            }
          }
        }
      }

      .description {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        margin-top: 15px;

        .title {
          font-size: 14px;
          font-weight: 500;
        }

        & > textarea {
          padding: 5px;
        }
      }

      .resume {
        display: flex;
        gap: 16px;
        align-items: center;
        &_btn {
          width: 144px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          border-radius: 20px;
          border: 1px solid $main-blue;
          height: 25px;
          overflow: hidden;
          margin-right: 4px;
          cursor: pointer;
          position: relative;
          transition: $anim-time;
          font-weight: bold;

          & * {
            cursor: pointer;
          }

          & > input {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            right: 0;
            z-index: 2;
            cursor: pointer !important;

            &::-webkit-file-upload-button {
              cursor: pointer;
            }
          }
        }
        &_file {
          display: flex;
          align-items: center;
          gap: 6px;
          & > span {
            text-decoration: underline;
          }
          & > svg {
            cursor: pointer;
            transform: scale(0.5);
            & > path {
              stroke: $main-blue;
              opacity: 0.8;
            }
          }
          & > svg:hover {
            & > path {
              opacity: 1;
            }
          }
        }
      }

      .buttons {
        display: flex;
        gap: 15px;
        margin: 25px auto;

        .back_btn {
          background: transparent;
          border: 1px solid #35435E;
          width: 130px;
          height: 32px;
          font-size: 16px;
          font-weight: 500;
        }

        .submit_btn {
          width: 130px;
          height: 32px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .article {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #474747;
    }
  }
}

.modal_success {

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 25px 50px 40px;

    .heading {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }

    .text {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
    }

    .channels {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      margin: 20px 0;
    }
  }
}