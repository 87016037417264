@import '../../../styles/index';

.manage_jobs {
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin: 1rem 0 2.5rem;
  margin: auto 0;
  padding: 0 20px;
  min-height: 50vh;
  height: 100%;

  .mob_wrap {
    display: flex;
    flex-direction: column;
    margin: 25px 0;
  }

  .wrap {
    display: flex;
    flex-direction: column;
    max-width: 1290px;
    width: 100%;
    padding: 25px 0;
  }

  .mob_job_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
  }

  .select {
    max-width: 350px;
    padding-bottom: 10px;
    padding-left: 15px;
    align-self: flex-start;
  }
}
