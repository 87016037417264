@import "../../../../styles/index";

.details {
  position: relative;
  min-height: calc(100vh - 150px);
  width: 100%;
  padding: 50px 0;
  background-color: $main-employer-c;

  .head {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    align-self: center;
    height: 130px;
    width: 320px;
    margin-bottom: 70px;

    &_heading {
      position: relative;
      z-index: 2;
      color: $c-white;
      @include text (700, 50px, 61px, 0,  $c-white);

      & > span {
        color: $main-button-c;
      }

      @media screen and (max-width: 600px) {
        @include text (600, 40px, 45px, 0,  $c-white);
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: -80px;
      top: -5px;
      width: 43px;
      height: 43px;
      background: rgba(164, 231, 252, 0.4);;
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      border-radius: 50px;

      @media screen and (max-width: 600px) {
        left: -15px;
        width: 33px;
        height: 33px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: -40px;
      top: 10px;
      width: 100px;
      height: 100px;
      background: rgba(164, 231, 252, 0.4);;
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      border-radius: 50px;

      @media screen and (max-width: 600px) {
        left: 10px;
        width: 80px;
        height: 80px;
      }
    }
  }

  .chat {
    display: flex;
    flex-direction: column;

    &_massage {
      position: relative;
      z-index: 2;
      align-self: flex-end;
      //min-width: 583px;
      max-width: 740px;
      margin: 30px 0 30px 10px;
      padding: 25px 30px;
      border-radius: 8px;
      background-color: $c-white;
      @include text (400, 20px, 25px, 0,  $main-text-c);

      &::after {
        position: absolute;
        z-index: -1;
        right: 15px;
        bottom: -22px;
        content: "";
        display: block;
        transform: rotate(-15deg);
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-right: 50px solid  $c-white;
        border-bottom: 30px solid transparent;
      }
    }

    &_massage_qst {
      position: relative;
      z-index: 2;
      background-color: $main-blue;
      border-radius: 8px;
      margin-right: 10px;
      max-width: 390px;
      padding: 25px 30px;
      @include text (400, 20px, 25px, 0,  $c-white);

      & > span {
        @include text (700, 24px, 29px, 0,  #FFC700);
      }

      &::before {
        position: absolute;
        z-index: -1;
        left: 15px;
        bottom: -22px;
        content: "";
        display: block;
        transform: rotate(75deg);
        width: 0;
        height: 0;
        border-top: 35px solid transparent;
        border-right: 40px solid  $main-blue;
        border-bottom: 35px solid transparent;
      }
    }
  }
}
