@import "../../styles/index";

.scrollPaper {
  @extend %scroll;
}

.MuiDialog-container {
  //width: 100%;
}

.modal {
  @extend %body-text;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }

  &__dialog {
    display: flex;
    flex-direction: column;
    width: 330px;
    min-height: 175px;
    align-items: center;
  }

  &__header {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
    padding: 0 10px 0 17px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .candidate__header {
    background-color: $main-candidate-c;

    .modal__heading {
      @include text (600, 18px);
      text-transform: uppercase;
    }
  }

  .employer_header {
    background-color: $main-employer-c;

    .modal__heading {
      @include text (500, 15px, $_color: $c-white);
      text-transform: uppercase;
    }

    .close_btn_icon {
      path {
        fill: #ffffff;
      }
    }
  }

  .admin_header {
    background-color: $main-admin-c;

    .modal__heading {
      @include text (500, 15px, $_color: $c-white);
      text-transform: uppercase;
    }

    .close_btn_icon {
      path {
        fill: #ffffff;
      }
    }
  }

  .close_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    padding: 20px 0;
  }

  &__buttons {
    padding-bottom: 15px;
  }
}

