@import "../../../styles/index";

.pagination_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin: auto 0;
  //margin: 26px 0 52px;
}

.pagination {

}
