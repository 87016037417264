@import "../../../../styles/index";

.why_us {
  padding-bottom: 70px;

  .heading {
    @include text(700, 27px, 33px, $main-text-c);
    text-align: center;
  }

  .text {
    @include text(500, 20px, 25px, $main-text-c);
    text-align: center;
    margin: 15px 0 30px;
  }

  .text_top {
    display: block;
    max-width: 665px;
  }

  .loc_item {
    position: absolute;
    top: 50px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    width: 200px;
    border-radius: 38px;
    background-color: $main-button-c;
    @include text(500, 18px, 22px, 0.7px, $main-text-c);
  }
}

.info_mob {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .loc_item {
    position: relative;
    top: 0;
  }
  
  .phone_wrap {
    position: relative;
  }

  .plus_wrap {
    position: relative;
    z-index: 2;
    width: 40px;
    height: 40px;
    margin: 30px 0;

    .plus {
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      height: 3.5px;
      width: 32px;
      border-radius: 50px;
      background-color: $main-employer-c;
    }

    .plus_vert {
      position: absolute;
      left: 0;
      top: 50%;
      transform: rotate(90deg);
    }
  }

  .phone_bt_line {
    margin-top: 20px;
  }
}

.info_deck {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1110px;

  .arrows_top {
    width: 440px;
  }

  .arrow_bt {
    max-width: 986px;
  }

  .img_list {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    margin-top: 15px;

    .img_wrap {
      display: flex;
      justify-content: center;
      position: relative;
      width: 320px;
      height: 514px;
    }
  }
}


.phone_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: 514px;
  border: 2px solid $bg-footer;
  border-radius: 50px;
  padding-top: 100px;

  .heading {
    position: absolute;
    top: 50px;
  }

  .tg_main_icon {
    width: 63px;
    height: 63px;
    margin: 15px 0 30px;
  }

  .tg_channels {
    margin-bottom: 30px;

    & > a {
      margin-bottom: 10px;
    }
  }

  .tg_heading {
    @include text(700, 16px, 20px);
    margin-bottom:15px;
  }
}

.example {
  width: 70%;
  border: 1px solid $main-employer-c;
  border-radius: 8px;
  margin-top: 25px;
  padding: 15px 20px;
  overflow: hidden;

  display: grid;
  justify-items: stretch;
  column-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-areas:
            "a"
            "b"
            "c";

  @media screen and (min-width: 700px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 280px 1fr;
    grid-template-areas:
            "a c"
            "b c";
  }

  .info_p {
    font-weight: 500;
  }

  .card_heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    grid-area: a;
  }

  .photo_wrap {
    width: 180px;
    height: 210px;
    border-radius: 10px;
    overflow: hidden;
    margin: 15px 0;
    @media screen and (max-width: 700px) {
      width: 150px;
      height: 170px;
    }


    .photo {
      box-sizing: border-box;
      width: 180px;
      height: 210px;
      object-fit: cover;

      @media screen and (max-width: 700px) {
        width: 150px;
        height: 170px;
      }
    }
  }

 .qualities {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 10px;
   margin-bottom: 10px;
   grid-area: b;
 }

  .contact_wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
    @media screen and (max-width: 700px) {
      order: 1;
      width: 100%;
      margin: 10px auto;
    }

    .contacts {
      display: flex;
      gap: 10px;
      grid-area: c;
      flex-direction: column;
      justify-content: space-between;
      max-width: 400px;
      max-height: 170px;
      width: 100%;
      margin: 0 auto;
      padding: 10px 15px;

      background-color: transparent;
      border: 1px solid #D0D0D0;
      border-radius: 5px;

      @media screen and (max-width: 700px) {
        flex-shrink: 1;
      }
    }
  }
}


