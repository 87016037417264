@import "../../../styles/index";

.verification {

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
  }

  &__text {
    @include text (400, 14px, 15px, $_color: #929292);
    margin-top: 10px;
  }

  .resend_wrap {
    position: relative;
    margin: 15px 0;
  }

  &__resend {
    display: inline-block;
    cursor: pointer;
    border: none;
    text-decoration: none;
    @include text (400, 16px, 17px, $_color: #595959);
    padding: 5px 0;
    background-color: transparent;

    &:hover {
      text-underline: #1D9FE8;
    }

    &::after {
      position: absolute;
      content: "";
      bottom: 2px;
      left: 0;
      width: 0;
      height: 1px;
      background-color: $main-text-c;
      border-radius: 10px;
      transition: width 0.3s;
    }

    &:hover::after {
      width: 100%;
    }
  }

  .error_text {
    text-align: center;
    color: $c-red;
  }

  &__submit {
    margin: 5px 0;
  }
}
