@import "../../../styles/index";

.work_filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding-bottom: 20px;

  button {
    height: 37px;
    font-size: 16px;
  }
}
