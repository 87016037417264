@import "../../styles/index";

.burger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: $main-candidate-c;
  width: 30px;
  height: 27px;
  padding: 0 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  border: none;
  transition: $anim-time;
  overflow: visible;

  &:hover {
    transform: scale(1.04);
  }
}

.burger_emplr {
  background-color: $main-employer-c;

    .burger_line {
      border-color: $c-white;
      background-color: $main-blue !important;
    }
}

.burger_line {
  display: block;
  width: 100%;
  border: 0.7px solid $main-blue;
  background-color: $main-blue;
  border-radius: 42px;
  transition: $anim-time;
}

.burger_line__mg {
  margin: 4px 0;
}
