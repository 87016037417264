@import "../../styles/index";

.search {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;


  @media screen and (min-width: $laptops-breakpoint) {
    flex-direction: row;
  }

  &__field {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $tablets-landscape-breakpoint) {
      flex-direction: row;
      justify-content: center;
      div:nth-child(n + 1) {
        margin-right: 5px;
      }
    }
  }

  &__button-wrapper {
    @media screen and (min-width: $tablets-landscape-breakpoint) {
      padding-top: 9px;
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: $laptops-breakpoint) {
      margin: 10px 0 15px;
    }
  }
}
