@import "../../styles/index";

.tabs {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  & > ul {
    display: flex;
    gap: 15px;
  }

  &_link {
    width: 220px;

    @media screen and (max-width: $mobile-landscape-breakpoint) {
      width: 160px;
    }
  }
}
