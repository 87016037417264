.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 27px;
  user-select: none;

  .logo-icon {
    width: 20px;
    height: 20px;
    margin-bottom: 2px;
  }

  .logo-title {
    margin-left: 7px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    //line-height: 20px;
    text-align: center;
  }
}
