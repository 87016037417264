@import '../../../../../styles/index';

.info_wrapper {
  display: flex;
  flex-direction: column;
  max-width: 820px;
  min-width: 400px;
  width: 100%;
  background-color: $c-white;
  border-radius: 6px;
  box-shadow: 0 0 23px rgba(0, 0, 0, 0.15);
  padding: 25px 30px;
  overflow-y: scroll;


  @extend %scroll;

  .main_info {
    width: 100%;

    .main_head {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      &_info > h3 {
        margin-bottom: 20px;
      }
    }

    .main_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }
  }

  .payment_info_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    margin: 15px 0;
    border-top: 0.5px solid rgba(21, 26, 37, 0.5);
    border-bottom: 0.5px solid rgba(21, 26, 37, 0.5);

    .qualification {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 10px;
    }

    .payment_info {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 15px;
      padding: 10px 0 10px 10px;
      width: 50%;
      height: 100%;
      border-left: 0.5px solid rgba(21, 26, 37, 0.5);

      @media screen and (max-width: 1180px) {
        width: 55%;
      }

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include text(400, 16px, 19px, 400);

        & > span {
          font-weight: 500;
          font-size: 25px;
          margin-bottom: 15px;

          @media screen and (max-width: 1120px) {
            font-size: 20px;
            margin-bottom: 10px;
          }
        }

        & > p {
          display: block;
          text-align: center;
        }
      }
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70%;
}
