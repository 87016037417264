//main color
$main-candidate-c: #00caba;
$main-employer-c: #3653b7;
$main-admin-c: #7030a0;
$main-blue: #35435e;
$main-green: #00caba;
$main-button-c: #ffc700;
$main-no-active-button-c: #d8e0ff;
$main-text-c: #35435e;
$main-no-active-text-c: #b4b4b4;
$secondary-e: #a4e7fc;
$c-white: #fff;
$c-red: #e90000;
$input-border-c: #86a1ff;
$info-grid-card-bc: #7030a0;
$c-danger: #f32a2a;
$c-scroll-bar: #B5DEE1;
$c-emplr-scroll-bar: #C5CDED;
$c-part-time-btn: #FFE699;
$c-full-time-btn: #CADFF2;

//job card
$bg-card: #f1f1f1;
$bg-active-card: #fff;
$bg-position-job-active: #ffbcbc;
$bg-position-job-inactive: #dcdcdc;
$bg-pagination-bt: #f5f7ff;
$bg-industries-circle: #dcf6ff;
$bg-industries-circle-active: #ffc700;

$bg-heading-pink-tone: #ffbcbc;
$bg-heading-pale-cyan: #b5edff;
$bg-selected-point: #115ace;
$bg-unselected-point: #edf2fc;
$bg-footer: #35435e;

$header-desk-h: 111px;
$header-mob-h: 64px;
$footer-desk-h: 80px;
$footer-mob-h: 318px;

$anim-time: 200ms;
$column-gap: 16px;

$input-radius: 57px;
$pagination-radius: 5px;

$container-width: 1280px;
$container-padding-laptop: 60px;
$container-padding-tablet: 40px;
$container-padding-mobile: 20px;
$scrollbar-width: 16px;

$breakpoint-for-jobs-list: 950px;
$breakpoint-for-employer-header: 985px;

$mobile-landscape-breakpoint: 568px;
$tablets-portait-breakpoint: 867px;
$tablets-landscape-breakpoint: 1024px;
$laptops-breakpoint: 1200px;
