@import "../../../styles/index";
.modal {

  &_content {
    padding: 25px 50px 40px;

    & > h3 {
      text-align: center;
    }

    &_cards {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin: 20px 0;

        @media screen and (max-width: 625px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .purchase_amount {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      gap: 20px;
      font-size: 20px;
      font-weight: 500;

      .totals {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        margin: 10px 0;
        @include text(500, 16px, 20px);
      }

      .line {
        width: 100%;
        border-bottom: 1px solid rgba(21%, 26%, 37%, 0.5);
      }

      .total_amount {
        @include text(600, 26px);
      }

      .purchase_btn {
        min-width: 160px;
        height: 40px;
        background-color: $main-button-c;
        transition: 0.2s;
        & > span {
          font-size: 16px;
          font-weight: 500;
        }

        &:hover {
          box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}