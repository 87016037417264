@import '../../styles';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 660px;
  gap: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  direction: rtl;
  padding: 5px 8px 12px;

  @extend %scroll;
}
