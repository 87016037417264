@import '../../styles/index';

.available_works {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 25px;
  min-height: 50vh;
  height: 100%;
}

.loading {
  margin: 100px auto;
}

.job_details {
  position: absolute;
  z-index: 1001;
  min-height: 100vh;
  margin-top: -64px;
  min-width: 100vw;
  background-color: #fff;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $main-candidate-c;
    min-height: 64px;
    padding: 0 20px;

    & > h3 {
        font-size: 20px;
        font-weight: 500;
    }

    .flag {
      svg{
        path {
          stroke: $main-blue;
        }
      }
    }
  }

  .content {
    background-color: $c-white;

    .wrap {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px 20px;
      max-width: 600px;
      width: 100%;
      margin: 0 auto;

      word-break: break-word;
    }

    .bottom_line {
      width: 100%;
      border-bottom: 0.5px solid rgba(21%, 26%, 37%, 0.5);
    }
  }

  .buttons {
    display: flex;
    gap: 15px;
    align-items: center;
    margin: 0 auto;

    button {
      font-size: 16px;
    }
  }
}