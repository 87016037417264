@import "../../styles/index";

.logout {
  background: transparent;
  //color: $c-white;
  width: 80px !important;
  padding: 3px ;

  &:hover {
    background: transparent;
    box-shadow: none !important;
  }

  &__candidate {
    svg path {
      fill: $main-blue;
    }

    p {
      @include text (400, 16px, $_color: $main-blue);
    }
  }

  &__employer {
    svg path {
      fill: $c-white;
    }

    p {
      @include text (400, 16px, $_color: $c-white);
    }
  }
}
