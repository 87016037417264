@import "../../styles/index";

.location {

}

.location_link {
  color: $main-text-c;
  position: relative;
  text-decoration: none;

  .link {
    //color: $main-employer-c;
    display: inline-block;
    color: $main-text-c;
    position: relative;
    text-decoration: none;
  }

  &::after {
    position: absolute;
    content: "";
    bottom: -1px;
    left: 0;
    width: 0;
    height: 2px;
    //background-color: $main-employer-c;
    background-color: $main-text-c;
    border-radius: 10px;
    transition: width 0.1s;
  }

  &:hover::after {
    width: 100%;
  }
}