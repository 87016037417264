@import "../../../styles/index";

.input-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
  width: 272px;

  @media screen and (min-width: $tablets-landscape-breakpoint) {
    width: 242px;
  }
}

.Input {
  width: 100%;
  outline: none;
  border: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: $main-blue;
}

.input {
  display: flex;
  align-items: center;
  height: 37px;
  padding: 0 7px 0 17px;
  background-color: $c-white;
  border: 0.5px solid $input-border-c;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
  border-radius: $input-radius;
  @include hover(border-color, $input-border-c, 0.2);

  input::placeholder {
    color: $main-no-active-text-c;
  }
}

.input__icon {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background-color: #F5F7FF;
  background-repeat: no-repeat;
  background-position: center;
  //background-position: x 10px;
  background-image: url('../../../assets/icons/search-input-icon.svg');
}

.input-heading {
  margin-bottom: 2px;
  margin-left: 14px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: $main-text-c;
}
