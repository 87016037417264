@import "../../../styles/index";

.hidden_input {
  display: none;
}
.label {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  padding: 3px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $main-text-c;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;

  &--active {
    color: $main-employer-c;
  }

  &:hover {
    background: #fafbfc;
    color: $main-employer-c;
  }

  .icon {
    display: none;
    path {
      fill: $main-employer-c;
    }
  }
}
.hidden_input:checked {
  & + .label {
    color: $main-employer-c;
    font-weight: 500;
    & > .icon {
      display: inline-block;
      margin-right: 8px;
    }
  }
}
