@import "../../styles/index";

.footer-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
  padding: 0 20px;
  background-color: $bg-footer;

  @media screen and (max-width: 1230px) {
    height: 195px;
  }

  @media screen and (max-width: $tablets-portait-breakpoint) {
    height: 320px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
  color: $c-white;

  @media screen and (min-width: $tablets-portait-breakpoint) {
    justify-content: center;
  }

  a {
    color: $c-white;
  }

  &__icon {
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 6px;
  }

  .footer__contact--desc {
    width: 100%;
    padding: 30px 0;

    @media screen and (max-width: $tablets-portait-breakpoint) {
      display: none;
    }
  }

  .footer__contact--mobile {
    margin: 0 auto;
    padding-bottom: 30px;

    @media screen and (min-width: $tablets-portait-breakpoint) {
      display: none;
    }
  }
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  min-width: 280px;
  font-size: 14px;
  text-align: center;

  @media screen and (max-width: $tablets-portait-breakpoint) {
    width: 280px;
  }

  @media screen and (min-width: $tablets-portait-breakpoint) {
    flex-direction: row;
    justify-content: space-between;
  }

  &__heading {
    margin-top: 25px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.06em;
  }

  &__email {
    width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 11px;

    @media screen and (min-width: $tablets-portait-breakpoint) {
      margin-bottom: 0;
      flex-shrink: 1;
    }
  }

  &__numbers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 238px;
    margin-bottom: 25px;

    @media screen and (min-width: $tablets-portait-breakpoint) {
      margin: 0;
      justify-self: flex-start;
    }

    &__phone &__whats-app {
      display: block;
      align-items: center;
      justify-content: center;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      display: block;
    }
  }

  .contact__tg-list--icon {
    width: 27px;
    height: 27px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px;
    background-size: 27px;
    background-image: url("../../assets/icons/footer-tg-icon.svg");
    pointer-events: none;

    @media screen and (min-width: $tablets-portait-breakpoint) {
      background-size: 20px;
      margin-right: 2px;
      margin-bottom: 1px;
    }
  }

  .contact__tg-list--link {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    border: 1px solid #FFFFFF;
    border-radius: 46.5413px;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    user-select: none;
    transition: transform 0.2s;

    &:hover {
      background-color: $c-white;

      p {
        @include hover(color, $main-text-c, 0.2);
      }
    }

    @media screen and (min-width: $tablets-portait-breakpoint) {
      width: 216px;
      height: 28px;
      font-weight: 400;
      font-size: 11px;
      margin: 0 5px;
    }

    @media screen and (max-width: 1230px) {
      width: 255px;
      height: 35px;
      font-weight: 400;
      font-size: 14px;
      margin: 5px 0;
    }

    p {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      @include hover(color, $main-text-c, 0.2);
    }
  }

  &__tg-list {
    margin-bottom: 35px;
    min-width: 280px;

    @media screen and (min-width: $tablets-portait-breakpoint) {
      display: flex;
      margin: 0;
    }

    @media screen and (max-width: 1230px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0;
    }
  }

  &__email--icon {
    background-image: url("../../assets/icons/email-icon.svg");
  }

  &__phone--icon {
    background-image: url("../../assets/icons/telef-icon.svg");
  }

  &__whats-app--icon {
    background-image: url("../../assets/icons/whats-app-icon.svg");
  }
}
