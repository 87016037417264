@import "./styles/index";

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @extend %body-text;

  &__content {
    padding-top: 40px;
    padding-bottom: 80px;
    flex-grow: 1;
  }
}
