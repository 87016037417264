@import "../../styles/index";

.modal_email {
  .modal_body {
    padding: 46px 38px 30px;
    & > p {
      font-size: 16px;
      font-weight: 700 !important;
      margin-bottom: 46px !important;
    }
  }
}

.modal {
  max-width: 400px;
  width: 100%;

  &_body {
    padding: 30px 20px;
    text-align: center;

    .message {
      @include text(600, 16px, 24px, $main-text-c);
      margin-bottom: 20px;
      white-space: pre-wrap;
    }

    .back_btn {
      width: 160px;
      height: 45px;
      font-size: 16px;
      font-weight: 500;
      margin: 0 auto;

      @media screen and (max-width: 686px) {
        width: 120px;
        height: 35px;
      }
    }
  }
}

.purchase_credits {
  background-color: $main-button-c;
  padding: 10px 20px;
  border-radius: 25px;
  color: $main-text-c;
  font-weight: 600;
  font-size: 16px;
}

.modal_delete_user {
  .buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;

    .delete {
      width: 150px;
      height: 40px;
      background-color: $c-red;
      color: $c-white;
    }

    .no {
      width: 150px;
      height: 40px;
      background-color: $main-button-c;
    }
  }
}

.banned_content_modal {
  .buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;

    .back {
      width: 150px;
      height: 40px;
      background-color: $c-red;
      color: $c-white;
    }

    .post_btn {
      width: 150px;
      height: 40px;
      background-color: $main-button-c;
    }
  }
}