@import "../../styles/index";

.drawer {
  display: flex;
  height: 100vh;
  width: 73vw;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;
}

.candidate {
  gap: 100px;

  &_nav_list {
    width: 100%;
    display: flex;
    flex-direction: column;

    &_nav_link {
      display: flex;
      justify-content: center;
      padding: 0;
      svg path {
        fill: $main-text-c !important;
      }
    }
  }

  .drawer_list {
    width: 100%;

    & > li:nth-child(n + 1) {
      display: flex;
      margin-top: 10px;
      justify-content: center;
    }
  }
}

.employer {
  position: relative;

  .seeker_btn {
    background-color: $main-button-c;
  }

  &_list {
    width: 100%;

    & > li:nth-child(n + 2) {
      display: flex;
    }
  }

  &_nav_link {
    svg path {
      fill: $main-text-c !important;
    }
  }

  &_nav_link_active {
    background-color: #C5E6FF !important;
  }

  &_list_no_auth {
    display: flex;
    flex-direction: column;
    gap: 50px;
    flex-grow: 1;
    width: 100%;

    & > li:nth-child(n + 2) {
      display: flex;
      margin-top: 10px;
      justify-content: center;
    }
    & > li:first-child {
      margin-bottom: 30px;
    }
  }

  .down_list {
    bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    .employer_link {
      margin-bottom: 10px;
    }
  }

  &_nav_link_price {
    display: flex;
    justify-content: center;
    padding: 0;
    svg path {
      fill: $main-text-c !important;
    }
  }
}
