* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: Roboto, sans-serif;
}

body {
  position: relative;
}
