@import "../../../styles/index";

.dropdown_wrap {
  width: 170px;
  //background-color: #3994D6;
  max-height: 45px;

  .dropdown {
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    box-sizing: border-box;

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;
      padding: 5px 0;
      font-weight: 500;
      cursor: pointer;
      background-color: transparent;
      border-bottom: 1px solid $main-blue;
    }

    &_heading {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      position: relative;
      outline: none;
      border: none;
      padding: 0;
      background-color: transparent;
      @include text(400, 16px, 20px);
      cursor: pointer;
    }

    &_list {
      width: 100%;
      margin-top: 5px;
      border-radius: 5px;
      position: absolute;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
      background-color: white;
      padding: 2px;
      z-index: 10;
      transform: translateY(-5px);
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s, visibility 0.2s, transform 0.2s;

      &_open {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }

      .dropdown_item {

        &_checkbox {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 5px;
          @include text(400, 14px);
          color: $main-text-c;
          &:hover {
            background-color: #f5f5f5;
          }

          & > svg {
            path {
              fill: $main-employer-c;
            }
          }
        }
      }
    }

    &_arrow {
      display: block;
      width: 16px;
      height: 16px;
      margin-right: 5px;
      background-image: url("../../../assets/icons/dropdown-arrow-down.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition: transform 0.2s;

      &_up {
        transform: rotate(180deg);
      }
    }
  }
}
