@import '../../../styles/utils/variables';

.wrap {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  margin-bottom: 32px;

  & > div {
    & > label {
      user-select: none;
      cursor: pointer;
      width: fit-content;
      display: block;
      font-size: 16px;
      font-weight: 400;
      color: $main-text-c;
      margin-left: 1.25rem;
      margin-bottom: 0.5rem;
    }
    & > input {
      padding: 0.625rem 1.25rem;
      border: 0.5px solid $input-border-c;
      border-radius: 60px;
      box-shadow: 0px 0px 14px 0px #00000026;
      font-size: 15px;
      font-weight: 300;
      line-height: 20px;
      outline: none;
      &::placeholder {
        color: #c4c4c4;
      }
      // &:focus-within {

      // }
    }
  }
  & > button {
    height: unset;
    padding: 0.625rem 1.25rem;
  }
}
