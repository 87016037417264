 @import '../../styles/index';

.review {
  position: relative;
  padding: 15px 20px;
  //width: 100%;
  border: 1px solid #D0D0D0;
  background-color: #FFFFFF;
  border-radius: 8px;
  overflow: hidden;

  //display: flex;
  //flex-direction: column;

  display: grid;
  justify-items: stretch;
  column-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-areas:
            "a"
            "b"
            "c";

  @media screen and (min-width: 700px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 280px 1fr;
    grid-template-areas:
            "a c"
            "b c";
  }


  &_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    grid-area: a;
  }

  &_index {
    font-size: 16px;
  }

  .photo_wrap {
    width: 200px;
    height: 230px;
    border-radius: 10px;
    overflow: hidden;
    margin: 15px 0;
    //border: 2px solid $main-employer-c;
  }

  .photo {
    box-sizing: border-box;
    width: 200px;
    height: 230px;
    object-fit: cover;
  }

  &_qualities {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    grid-area: b;
  }

  .contact_wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
    @media screen and (max-width: 700px) {
      order: 1;
      width: 100%;
      margin: 10px auto;
    }

    .wrap {
      align-self: center;
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 5px 0;

      @media screen and (max-width: 1264px) {
        flex-direction: column;
      }
    }

    .applied {
      font-size: 15px;
      font-weight: 400;
      color: #595959;
    }

    .shortlisted {
      padding: 1px 15px;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      background-color: $main-button-c;
      border-radius: 10px;
    }
  }
}

.review_shortlisted {
  //background-color: #EAFAFE;
  border: 1px solid $main-employer-c;

  //&:before {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  height: 5px;
  //  width: 100%;
  //  background-color: $main-employer-c;
  //  opacity: 0.5;
  //}
 }

 .review_not_shortlisted {
   //&:before {
   //     content: '';
   //     position: absolute;
   //     top: 0;
   //     left: 0;
   //     height: 5px;
   //     width: 100%;
   //     background-color: #FFBCBC;
   //     opacity: 0.5;
   //}
 }

.contacts {
  display: flex;
  gap: 10px;
  grid-area: c;
  flex-direction: column;
  justify-content: space-between;
  max-width: 400px;
  max-height: 170px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 15px;

  background-color: transparent;
  border: 1px solid #D0D0D0;
  border-radius: 5px;

  @media screen and (max-width: 700px) {
    flex-shrink: 1;
  }

  .circular {
    margin: 10px auto;
  }

  .info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;

    .resume {
      font-weight: 600;
      font-size: 16px;

      &_btn {
        font-size: 16px;
        line-height: 17px;
        height: 17px;
        margin-top: 4px;
        font-weight: 400;
        border-bottom: 1px solid $main-blue;
        color: $main-blue;
        width: content-box;
        cursor: pointer;
      }
    }
  }

  &_title {
    @include text(400, 16px, 20px, $main-text-c);
    text-align: center;
  }

  .shortlists_left {
    align-self: center;
  }

  .btn {
    text-align: center;
    align-self: center;
    background-color: $main-button-c;
    transition: $anim-time;

    &:hover {
      box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.15);
    }

    & > span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}