@import "../../../styles/index";

.save_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 20px;
  border: none;
  background-color: transparent;
  & > svg {

    path {
      stroke: $main-blue;
    }
  }
}
