@import "../../../styles/index";

.Button {
  position: relative;
  display: inline-block;
  width: 130px;
  height: 32px;
  border: none;
  border-radius: 57px;
  text-align: center;
  @extend %buttons;
  color: $main-blue;
  background-color: $main-button-c;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition-duration: $anim-time;
  user-select: none;
  font-size: 16px;

  &:focus {
    outline: none;
  }

  &:hover {
    //opacity: 0.9;
    //box-shadow: 0 0 0 1px $main-blue;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    //border-color: $c-light-gray;
    cursor: default;
  }

  .btn_bump {
    position: absolute;
    top: 6px;
    right: 10px;
    width: 20px;
    height: 20px;
  }

  &--details {
    border: 1px solid $main-blue;
    background-color: $c-white;
  }

  &--search {
    width: 270px;
    height: 44px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    box-shadow: 0 0 13.2101px rgba(0, 0, 0, 0.05);

    @media screen and (min-width: $tablets-landscape-breakpoint) {
      height: 37px;
      width: 167px;
    }
  }

  &--selected {
    border: none;
    color: $c-white;
    //background-color: $c-very-dark-gray;
  }

  &--arrowUp {
    //background-image: url(../../accets/Arrow_up_black.svg);

    &::before {
      content: "Back to top";
      display: block;
      width: max-content;
      @extend %small-text;
      //color: $c-dark-gray;
      transform: translateX(-120%);
      transition-duration: $anim-time;
    }

    &:hover::before {
      //color: $c-very-dark-gray;
    }
  }



  &--select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    padding: 0 12px;

    &:hover {
      //border-color: $c-dark-gray;
    }

    &:focus {
      //border-color: $c-very-dark-gray;
    }
  }

  &--close {
    height: 16px;
    width: 16px;
    border: none;
    //background-image: url(../../accets/Close_gray.svg);

    &:hover {
      //background-image: url(../../accets/Close.svg);
    }
  }
}

.upload-photo {
  position: absolute;
  bottom: 20px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid $main-blue;
  height: 25px;
  font-size: 14px;
  font-weight: 500;
  right: calc(50% - 65px);
}
